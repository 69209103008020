import styled from 'styled-components';
import { CardContent, Container, Card } from '@mui/material';

export const OnboardingContainer = styled.div`
  background-color: #fafafa;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: fixed;
`;

export const OnboardingContentWrapper = styled.div`
  height: calc(100vh - 80px);
  margin-top: 80px;
  background-color: #fafafa;
  overflow: auto;
`;

export const OnboardingContent = styled(Container).attrs({
  maxWidth: 'md',
})`
  padding: 40px 0;
  overflow: auto;
  & > ul {
    padding: 0;
    margin: 0;
  }
`;

export const Wrapper = styled.div`
  height: 80px;
  left: 0px;
  top: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  position: fixed;
  width: 100%;
  background-color: #ffffff;
  z-index: 999999;
`;

export const NavBar = styled.div``;

export const Content = styled.div`
  display: flex;
  padding: 20px 40px 20px 20px;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
`;

export const SettingsPageCard = styled(Card)`
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #FFF;
  box-shadow: none;
`;

export const SettingsPageCardContent = styled(CardContent)`
  padding: 0px !important;
  width: 100%;
`;
