import React, { useState } from 'react';
import { Typography, Stack, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { getStepIcon, getStepTitle, getCardBgColor } from '../../../utils/sequenceHelper';

import LinkedIn from './LinkedIn';
import General from './General';
import Wait from './Wait';
import Email from './Email';

import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import CreateIcon from '@mui/icons-material/Create';
import { SequenceSummaryChip, WaitContent } from './style';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme, action }) => ({
  ...(action !== 'close' && {
    borderBottom: `none`,
    boarderRadius: '10px',
    '&.Mui-expanded': {
      borderBottom: `none`,
    },
  }),
  '&:before': {
    display: 'none',
  },
}));

function Card({ event, index, formProps, onlyViewable, waitTime, onEdit }) {
  const [expanded, setExpanded] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const linkedinTypes = ['linkedin', 'linkedinConnection', 'linkedinMessage', 'linkedinMail'];
  const readonlyTypes = ['linkedinViewProfile', 'selectEmailTemplate', 'endsquence'];
  const generalTypes = ['general', 'call', 'sms'];
  const showOnlyTimeNoAccordion = ['wait'];

  return (
    <Accordion
      disableGutters
      sx={{ margin: '12px 0px', borderRadius: '5px' }}
      expanded={expanded}
      onChange={() => {
        setExpanded(!expanded);
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <MuiAccordionSummary
        expandIcon={
          ![...readonlyTypes, ...showOnlyTimeNoAccordion].includes(event.eventType) ? (
            <ExpandMoreIcon />
          ) : null
        }
        sx={{
          // background: getCardBgColor(event.eventType),
          margin: 0,
          padding: '20px 24px',
          '& .MuiAccordionSummary-content': {
            margin: 0,
          },
        }}
      >
        <Stack direction="row" justifyContent={'space-between'} alignItems={'center'} width="100%">
          <Stack direction="row" gap={'20px'} alignItems={'center'}>
            <SequenceSummaryChip sx={{ opacity: expanded ? '1' : '0.4' }}>
              {index + 1}
            </SequenceSummaryChip>
            <Stack direction="row" gap={'16px'} alignItems={'center'}>
              {getStepIcon(event.eventType)}
              <Typography color="textSecondary">{getStepTitle(event.eventType)}</Typography>
              {isHovered && !readonlyTypes.includes(event.eventType) ? (
                <CreateIcon
                  sx={{ color: '#00000042', fontSize: '20px', cursor: 'pointer' }}
                  onClick={(e) => {
                    e?.stopPropagation();
                    onEdit?.(
                      event,
                      event.eventType === 'email'
                        ? event?.emailTemplates?.length > 0
                          ? event?.emailTemplates[0]
                          : {}
                        : {},
                    );
                  }}
                />
              ) : null}
            </Stack>
          </Stack>
          {showOnlyTimeNoAccordion.includes(event.eventType) ? (
            <WaitContent>
              Wait {event?.waitTime}{' '}
              {event?.waitTime === 1 ? event?.unit.slice(0, -1) : event?.unit}
            </WaitContent>
          ) : !readonlyTypes.includes(event.eventType) && Math.floor(waitTime / 86400) ? (
            <WaitContent>
              Wait{' '}
              {Math.floor(waitTime / 86400) > 1
                ? Math.floor(waitTime / 86400) + ' days'
                : Math.floor(waitTime / 86400) + ' day'}
            </WaitContent>
          ) : null}
        </Stack>
      </MuiAccordionSummary>

      {![...readonlyTypes, ...showOnlyTimeNoAccordion].includes(event.eventType) && (
        <MuiAccordionDetails sx={{ padding: '0px 24px 20px' }}>
          {linkedinTypes.includes(event.eventType) ? (
            <LinkedIn event={event} onlyViewable={onlyViewable} formProps={formProps} />
          ) : null}
          {generalTypes.includes(event.eventType) ? (
            <General onlyViewable={onlyViewable} event={event} />
          ) : null}
          {event.eventType === 'wait' ? <Wait event={event} onlyViewable={onlyViewable} /> : null}
          {event.eventType === 'email' ? (
            <Email showHover event={event} formProps={formProps} onlyViewable={onlyViewable} />
          ) : null}
        </MuiAccordionDetails>
      )}
    </Accordion>
  );
}

export default Card;
