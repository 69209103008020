import * as contactTypes from './contactTypes';

export const clearContactData = () => ({
  type: contactTypes.CLEAR_CONTACT_DATA,
});

export const fetchBullhornMatches = (contact, matchType, enrolmentId, sequenceId) => ({
  type: contactTypes.FETCH_BULLHORN_MATCHES,
  contact,
  matchType,
  enrolmentId,
  sequenceId,
});

export const setBullhornMatches = (matches, match) => ({
  type: contactTypes.SET_BULLHORN_MATCHES,
  matches,
  match,
});

export const syncBullhornContact = (contactId, match, resolve, reject) => ({
  type: contactTypes.SYNC_BULLHORN_CONTACT,
  contactId,
  match,
  resolve,
  reject,
});

export const syncBullhornNewContact = (contactId, bullhornType, resolve, reject) => ({
  type: contactTypes.SYNC_BULLHORN_NEW_CONTACT,
  contactId,
  bullhornType,
  resolve,
  reject,
});

export const fetchVincereMatches = (contact, matchType, enrolmentId, sequenceId) => ({
  type: contactTypes.FETCH_VINCERE_MATCHES,
  contact,
  matchType,
  enrolmentId,
  sequenceId,
});

export const setVincereMatches = (matches, match) => ({
  type: contactTypes.SET_VINCERE_MATCHES,
  matches,
  match,
});

export const syncVincereContact = (contactId, match, resolve, reject) => ({
  type: contactTypes.SYNC_VINCERE_CONTACT,
  contactId,
  match,
  resolve,
  reject,
});

export const syncVincereNewContact = (contactId, vincereType, resolve, reject) => ({
  type: contactTypes.SYNC_VINCERE_NEW_CONTACT,
  contactId,
  vincereType,
  resolve,
  reject,
});

export const fetchSalesforceMatches = (contact, matchType, enrolmentId, sequenceId) => ({
  type: contactTypes.FETCH_SALESFORCE_MATCHES,
  contact,
  matchType,
  enrolmentId,
  sequenceId,
});

export const setSalesforceMatches = (matches, match) => ({
  type: contactTypes.SET_SALESFORCE_MATCHES,
  matches,
  match,
});

export const syncSalesforceContact = (contactId, match, resolve, reject) => ({
  type: contactTypes.SYNC_SALESFORCE_CONTACT,
  contactId,
  match,
  resolve,
  reject,
});

export const syncSalesforceNewContact = (contactId, salesforceType, resolve, reject) => ({
  type: contactTypes.SYNC_SALESFORCE_NEW_CONTACT,
  contactId,
  salesforceType,
  resolve,
  reject,
});

export const fetchHubspotMatches = (contact, matchType, enrolmentId, sequenceId) => ({
  type: contactTypes.FETCH_HUBSPOT_MATCHES,
  contact,
  matchType,
  enrolmentId,
  sequenceId,
});

export const setHubspotMatches = (matches, match) => ({
  type: contactTypes.SET_HUBSPOT_MATCHES,
  matches,
  match,
});

export const syncHubspotContact = (contactId, match, resolve, reject) => ({
  type: contactTypes.SYNC_HUBSPOT_CONTACT,
  contactId,
  match,
  resolve,
  reject,
});

export const syncHubspotNewContact = (contactId, hubspotType, resolve, reject) => ({
  type: contactTypes.SYNC_HUBSPOT_NEW_CONTACT,
  contactId,
  hubspotType,
  resolve,
  reject,
});

export const fetchJobAdderMatches = (contact, matchType, enrolmentId, sequenceId) => ({
  type: contactTypes.FETCH_JOBADDER_MATCHES,
  contact,
  matchType,
  enrolmentId,
  sequenceId,
});

export const setJobAdderMatches = (matches, match) => ({
  type: contactTypes.SET_JOBADDER_MATCHES,
  matches,
  match,
});

export const syncJobAdderContact = (contactId, match, resolve, reject) => ({
  type: contactTypes.SYNC_JOBADDER_CONTACT,
  contactId,
  match,
  resolve,
  reject,
});

export const syncJobAdderNewContact = (contactId, jobAdderType, resolve, reject) => ({
  type: contactTypes.SYNC_JOBADDER_NEW_CONTACT,
  contactId,
  jobAdderType,
  resolve,
  reject,
});

export const sendContactEmail = (contactId, payload, resolve, reject) => ({
  type: contactTypes.SEND_CONTACT_EMAIL,
  contactId,
  payload,
  resolve,
  reject,
});

export const putContactEmail = (contactId, taskId, payload, resolve, reject) => ({
  type: contactTypes.PUT_CONTACT_EMAIL,
  contactId,
  taskId,
  payload,
  resolve,
  reject,
});

export const addContactToSequence = (sequenceId, contactId, resolve, reject) => ({
  type: contactTypes.ADD_CONTACT_TO_SEQUENCE,
  sequenceId,
  contactId,
  resolve,
  reject,
});

export const contactBulkAction = (action, contacts, payload, resolve, reject) => ({
  type: contactTypes.CONTACT_BULK_ACTION,
  action,
  contacts,
  payload,
  resolve,
  reject,
});

export const checkEnrolmentAndFetchErrors = () => ({
  type: contactTypes.CHECK_ENROLLMENT_AND_FETCH_ERROR,
});

export const updateContactSocialMedia = (contactId, payload, resolve, reject) => ({
  type: contactTypes.UPDATE_CONTACT_SOCIAL_MEDIA,
  contactId,
  payload,
  resolve,
  reject,
});

export const postContactDetails = (contactId, formType, data, resolve, reject) => ({
  type: contactTypes.POST_CONTACT_DETAIL,
  contactId,
  formType,
  data,
  resolve,
  reject,
});

export const putContactDetails = (contactId, formType, contactDetailId, data, resolve, reject) => ({
  type: contactTypes.PUT_CONTACT_DETAIL,
  contactId,
  formType,
  contactDetailId,
  data,
  resolve,
  reject,
});

export const deleteContactDetail = (contactId, formType, contactDetailId, resolve, reject) => ({
  type: contactTypes.DELETE_CONTACT_DETAIL,
  contactId,
  formType,
  contactDetailId,
  resolve,
  reject,
});

export const fetchContact = (contactId) => ({
  type: contactTypes.FETCH_CONTACT,
  contactId,
});
export const fetchContactTask = (contactId) => ({
  type: contactTypes.FETCH_CONTACT_TASK,
  contactId,
});
export const fetchContactNote = (contactId, filters) => ({
  type: contactTypes.FETCH_CONTACT_NOTE,
  contactId,
  filters,
});
export const fetchContactNotes = (contactId, filters, paging) => ({
  type: contactTypes.FETCH_CONTACT_NOTES,
  contactId,
  filters,
  paging,
});
export const fetchContactTasks = (contactId, filters, paging) => ({
  type: contactTypes.FETCH_CONTACT_TASKS,
  contactId,
  filters,
  paging,
});
export const fetchSequenceEnrollment = (contactId) => ({
  type: contactTypes.FETCH_SEQUENCE_ENROLL,
  contactId,
});
export const fetchSequenceEnrollments = (contactId, filters, paging) => ({
  type: contactTypes.FETCH_SEQUENCE_ENROLLMENTS,
  contactId,
  filters,
  paging,
});
export const putEnrollment = (id, enrollment) => ({
  type: contactTypes.UPDATE_ENROLLMENT_STATUS,
  id,
  enrollment,
});

export const setContact = (contact) => ({
  type: contactTypes.SET_CONTACT,
  contact,
});
export const setContactCrmLoading = (loading) => ({
  type: contactTypes.SET_CONTACT_CRM_CONNECTION,
  loading,
});
export const setFetchContactCrmLoading = (loading) => ({
  type: contactTypes.SET_FETCH_CONTACT_CRM_CONNECTION,
  loading,
});
export const setContactTask = (tasks) => ({
  type: contactTypes.SET_CONTACT_TASK,
  tasks,
});
export const setContactNote = (notes) => ({
  type: contactTypes.SET_CONTACT_NOTE,
  notes,
});
export const setContactNotes = (data) => ({
  type: contactTypes.SET_CONTACT_NOTES,
  data,
});
export const setContactTasks = (data) => ({
  type: contactTypes.SET_CONTACT_TASKS,
  data,
});
export const postContactNote = (note) => ({
  type: contactTypes.POST_CONTACT_NOTE,
  note,
});
export const setSequenceEnroll = (enrolments) => ({
  type: contactTypes.SET_SEQUENCE_ENROLLMENT,
  enrolments,
});

export const setSequenceEnrollments = (data) => {
  return { type: contactTypes.SET_SEQUENCE_ENROLLMENTS, data };
};

export const fetchContactOptionsFilters = (tab) => ({
  type: contactTypes.FETCH_CONTACT_OPTIONS_FILTERS,
  tab,
});
export const setContactOptionsFilters = (data, filters) => ({
  type: contactTypes.SET_CONTACT_OPTIONS_FILTERS,
  data,
  filters,
});

export const fetchContactTabs = () => ({
  type: contactTypes.FETCH_CONTACT_TABS,
});
export const setContactTabs = (tabs) => ({
  type: contactTypes.SET_CONTACT_TABS,
  tabs,
});

export const postContact = (contact, resolve, reject) => ({
  type: contactTypes.POST_CONTACT,
  contact,
  resolve,
  reject,
});

export const putContact = (contactId, contact, resolve, reject) => ({
  type: contactTypes.PUT_CONTACT,
  contactId,
  contact,
  resolve,
  reject,
});

export const fetchJobs = (data, resolve, reject) => ({
  type: contactTypes.FETCH_FILTER_JOB_OPTIONS,
  data,
  resolve,
  reject,
});

export const fetchAddress = (data, resolve, reject) => ({
  type: contactTypes.FETCH_FILTER_EMP_OPTIONS,
  data,
  resolve,
  reject,
});

export const fetchLocation = (data, resolve, reject) => ({
  type: contactTypes.FETCH_FILTER_LOCATION_OPTIONS,
  data,
  resolve,
  reject,
});

export const fetchSequenceList = (data, resolve, reject) => ({
  type: contactTypes.FETCH_SEQUENCE_OPTIONS,
  data,
  resolve,
  reject,
});

export const updateContact = (contact, contactId) => ({
  type: contactTypes.UPDATE_CONTACT,
  contact,
  contactId,
});

export const deleteContact = (contactId, resolve, reject, notRedirect = false) => ({
  type: contactTypes.DELETE_CONTACT,
  contactId,
  resolve,
  reject,
  notRedirect,
});

export const fetchContacts = (paging, filters, sort) => ({
  type: contactTypes.FETCH_CONTACTS,
  paging,
  filters,
  sort,
});

export const setContacts = (data) => ({
  type: contactTypes.SET_CONTACTS,
  data,
});

export const addContacts = (contacts, resolve, reject) => ({
  type: contactTypes.ADD_CONTACTS,
  contacts,
  resolve,
  reject,
});

export const toggleViewContactProfile = (selectedContactId = 0) => {
  return {
    type: contactTypes.TOGGLE_CONTACT_PROFILE,
    selectedContactId,
  };
};

export const selectedContactIds = (selectedContactIds = []) => {
  return {
    type: contactTypes.SELECTED_CONTACT_IDS,
    selectedContactIds,
  };
};

export const clearContactFilters = () => ({
  type: contactTypes.CLEAR_CONTACTS_FILTERS,
});

export const fetchContactsList = (data, resolve, reject) => ({
  type: contactTypes.FETCH_CONTACTS_LISTS,
  data,
  resolve,
  reject,
});
