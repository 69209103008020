import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link, useLocation } from 'react-router-dom';
import {
  List,
  ListItem,
  Collapse,
  Drawer,
  Box,
  ListItemIcon,
  Typography,
  Tooltip,
  Divider,
  Stack,
} from '@mui/material';
import { toggleSidebar } from 'src/modules/app/actions/appActions';

import moment from 'moment';
import { cloneDeep, isEqual } from 'lodash';
import storage from 'src/utils/storageUtils';
import getMenuItems from 'src/config/menu';
import images from 'src/config/images';
import useStyles, { ModalBgSetup } from './styles';

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import pckg from '../../../../package.json';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button, Modal, Spacer } from 'src/components/shared';

import { usePopupState, bindPopover } from 'material-ui-popup-state/hooks';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { calculateDaysDifference } from '../../../utils/helper';

const trialSubscriptionPlans = [
  'One-Membership-Freemium-Monthly-GBP-Monthly',
  'One-Membership-Trial-Plan-GBP-14-days',
];

const trialSubscriptionPlansPriceId = [
  // 'Starter-Monthly-Plan-GBP-Monthly',
  'One-Membership-Trial-Plan-GBP-14-days',
];

const Sidebar = ({ hasLmsAccess, hasOutreachAccess }) => {
  const classes = useStyles();
  const history = useHistory();
  let locations = useLocation();
  const [path, setPath] = useState(locations.pathname);
  const tenant = useSelector((state) => state.app.tenant);
  const user = useSelector((state) => state.auth.user);
  let [menuItems, setMenuItems] = useState([]);
  const [isDashboard, setIsDashboard] = useState(false);
  const [isPrimaryMenu, setIsPrimaryMenu] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const popupState = usePopupState({ variant: 'popover', popupId: 'menu-dropdown' });

  const dispatch = useDispatch();
  const { sideBarIsOpen, taskCount } = useSelector((state) => state.app);
  const pinnedBoards = useSelector((state) => state.boards.boards);
  const isReseller = tenant?.isReseller || false;
  const isPartner = tenant?.isPartner || false;
  const isAdmin = user?.isAdmin || false;

  useEffect(() => {
    let menuType = path.split('/')[1];
    if (hasLmsAccess && hasOutreachAccess === false) {
      menuType = 'admin';
    }
    setMenuItems(
      getMenuItems(
        path,
        menuType,
        isReseller,
        isPartner,
        user?.acl?.accessLevel?.toLowerCase(),
        hasLmsAccess,
        hasOutreachAccess,
      ),
    );
  }, [tenant, user, hasLmsAccess, hasOutreachAccess]);

  useEffect(() => {
    if (
      // locations.pathname.split('/')[1] !== 'profile' &&
      locations.pathname.split('/')[1] !== 'admin'
    ) {
      setIsPrimaryMenu(true);
    } else {
      setIsPrimaryMenu(false);
    }
  }, [locations]);

  useEffect(() => {
    setIsDashboard(false);
    if (locations.pathname === '/dashboard') {
      // setIsDashboard(true);
    }
    let menuType = locations.pathname.split('/')[1];
    if (hasLmsAccess && hasOutreachAccess === false) {
      menuType = 'admin';
    }

    if (path.split('/')[1] !== locations.pathname.split('/')[1]) {
      if (locations.pathname.split('/')[1] !== 'dashboard') {
        menuItems = getMenuItems(
          locations.pathname,
          menuType,
          isReseller,
          isPartner,
          user?.acl?.accessLevel?.toLowerCase(),
          hasLmsAccess,
          hasOutreachAccess,
        );
        setMenuItems([...menuItems]);
        updateActiveState(menuItems);
        setMenuItems(cloneDeep(menuItems));
      } else if (
        locations.pathname.split('/')[1] !== 'profile' &&
        locations.pathname.split('/')[1] !== 'admin'
      ) {
        menuItems = getMenuItems(
          locations.pathname,
          menuType,
          isReseller,
          isPartner,
          user?.acl?.accessLevel?.toLowerCase(),
          hasLmsAccess,
          hasOutreachAccess,
        );
        setMenuItems([...menuItems]);
        updateActiveState(menuItems);
        setMenuItems(cloneDeep(menuItems));
      } else {
        menuItems = getMenuItems(
          locations.pathname,
          menuType,
          isReseller,
          isPartner,
          user?.acl?.accessLevel?.toLowerCase(),
          hasLmsAccess,
          hasOutreachAccess,
        );
        setMenuItems([...menuItems]);
        updateActiveState(menuItems);
        setMenuItems(cloneDeep(menuItems));
      }
    } else if (menuItems.length) {
      updateActiveState(menuItems);
      setMenuItems(cloneDeep(menuItems));
    }
    setPath(locations.pathname);
  }, [locations.pathname]);

  const isUserOnTrial = (user) => {
    if (!user?.tenants?.length || !user?.tenants[0]?.subscription) {
      return false;
    }
    const subscriptionData = user.tenants[0].subscription;
    const hasActiveStatus = Object.values(subscriptionData).filter(
      (item) =>
        item &&
        Object.keys(item).length > 0 &&
        item?.status === 'active' &&
        !trialSubscriptionPlansPriceId.includes(item?.item_price_id),
    );
    return hasActiveStatus?.length > 0;
  };

  const activeTrialSubscriptionSubscription = useMemo(() => {
    if (!user?.tenants?.length || !user?.tenants[0]?.subscription) {
      return {};
    }
    const subscriptionData = user.tenants[0].subscription;
    const activeKey = Object.keys(subscriptionData).find(
      (key) => trialSubscriptionPlans.includes(key) && subscriptionData[key]?.id,
    );
    if (!activeKey) return {};
    return subscriptionData[activeKey];
  }, [user]);

  const showUpgradePlan = useMemo(() => isUserOnTrial(user), [user]);

  const updateActiveState = (arr) => {
    (arr || []).forEach((i) => {
      let currentPAth = locations.pathname.split('/')[1];
      if (currentPAth === 'prospect') {
        currentPAth = `${locations.pathname.split('/')[1]}/${locations.pathname.split('/')[2]}`;
      }
      if (locations.pathname === '/profile/integrations') {
        currentPAth = `${locations.pathname.split('/')[1]}/${locations.pathname.split('/')[2]}`;
      }
      if (isEqual(i.link, locations.pathname) || isEqual(i.link, `/${currentPAth}`)) {
        if (!i.child?.length) {
          i.activeClass = 'active';
        } else {
          updateActiveState(i.child);
        }
      } else {
        if (i.activeClass !== undefined) i.activeClass = '';
        updateActiveState(i.child);
      }
    });
  };

  const handleClick = (parent, child) => async (event) => {
    if (parent.id == 'learning') {
      window.location.replace(parent.link);
      return false;
    } else {
      const parentNodeChild = menuItems.find((e) => e.id === parent.id);

      if (parent.id === 'back') {
        const referral = await storage.get('REFERRAL_ROUTE');
        if (referral && referral !== '') {
          await storage.del('REFERRAL_ROUTE');
          history.push(referral);
        } else {
          history.push('/');
        }
        event.preventDefault();
        return false;
      }
      if (parentNodeChild?.child?.length) {
        // event is null then child active will not be added
        // null is expand icon
        if (event) {
          menuItems.forEach((e) => {
            e?.child?.forEach((c) =>
              c.id === (child ? child : parentNodeChild?.child[0]).id
                ? (c.activeClass = 'active')
                : (c.activeClass = ''),
            );
            e.activeClass = '';
          });
        }
        if (!child) {
          const childNode = menuItems.find((e) => e.id === parent.id);
          if (!sideBarIsOpen) {
            if (!childNode.in) {
              childNode.in = !childNode.in;
            }
          } else {
            childNode.in = !childNode.in;
          }
        }
      } else {
        menuItems.forEach((e) => {
          e?.child?.forEach((c) => (c.activeClass = ''));
          parentNodeChild.id === e.id ? (e.activeClass = 'active') : (e.activeClass = '');
        });
      }
      menuItems = cloneDeep(menuItems);
      setMenuItems(cloneDeep(menuItems));
      !sideBarIsOpen && dispatch(toggleSidebar());
    }
  };

  const togglePopup = (event) => {
    popupState.setAnchorEl(event.target);
    popupState.open();
  };

  const closePopover = (event) => {
    popupState.setAnchorEl(undefined);
    popupState.close();
  };

  const updateSubscription = () => {
    if (user?.acl?.accessLevel?.toLowerCase() === 'admin') {
      history.push('/admin/billing/subscriptions');
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <div
      className="sidebar-wrapper"
      style={sideBarIsOpen ? { width: isDashboard ? 300 : 230, minWidth: 230 } : { width: 64 }}
    >
      <Drawer
        variant="permanent"
        docked={undefined}
        className={classes.sliderDrawer}
        classes={{
          paper: sideBarIsOpen
            ? isDashboard
              ? classes.sliderPaperDashboard
              : classes.sliderPaper
            : classes.drawerPaperClose,
        }}
        open={sideBarIsOpen}
      >
        <Box
          display="flex"
          className={sideBarIsOpen ? classes.headerLogo : classes.headerLogoCollapsed}
          onClick={() => {
            if (hasOutreachAccess) {
              history.push('/');
            } else {
              window.location.replace(process.env.REACT_APP_LMS_URL);
            }
          }}
          style={{ cursor: 'pointer' }}
        >
          <img
            src={sideBarIsOpen ? images.app.logoWhite : images.app.logoCollapsed}
            alt=""
            border="0"
            className={`img-fluid ${classes.leftBoxIcon}`}
          />
        </Box>
        <SimpleBar
          style={{
            maxHeight: 'calc(100vh - 105px)',
            minHeight: 'calc(100vh - 105px)',
            overflowX: 'hidden',
          }}
        >
          <Box
            style={{
              maxHeight: 'calc(100vh - 105px)',
              minHeight: 'calc(100vh - 105px)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <List component="nav" className={classes.root}>
              {menuItems.map((item, index) => (
                <Box
                  key={`menu-list-item-wrapper-${item.id}-${index}`}
                  // {...(item.type === 'dropdown' && bindHover(popupState))}
                  {...(item.type === 'dropdown' && {
                    onMouseEnter: togglePopup,
                    onMouseLeave: closePopover,
                  })}
                >
                  <Tooltip
                    title={!sideBarIsOpen ? item.title : ''}
                    arrow
                    placement={sideBarIsOpen ? 'bottom' : 'right'}
                    key={`menu-tooltip-item-${item.id}-${index}`}
                  >
                    {item?.type && item?.type === 'divider' ? (
                      <Box sx={{ padding: '4px 0px' }}>
                        <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.23)' }} />
                      </Box>
                    ) : item.id == 'learning' ? (
                      <a href={item.link}>
                        <ListItem
                          button
                          className={`${classes.navListTitle} MenuItem-${item.id} SideBar-${
                            sideBarIsOpen ? 'open' : 'close'
                          } ${item.activeClass || ''}`}
                          key={`menu-list-item-${item.id}-${index}`}
                          onClick={handleClick(item)}
                        >
                          {item.menuIcon && (
                            <ListItemIcon
                              className={`${classes.listItemIcon} ${
                                item?.child?.find((e) => e.activeClass === 'active') &&
                                !sideBarIsOpen
                                  ? 'active'
                                  : ''
                              }`}
                            >
                              {item.menuIcon}
                            </ListItemIcon>
                          )}
                          <Typography variant="body1" className="career_portal sideText">
                            {item.title}
                          </Typography>
                        </ListItem>
                      </a>
                    ) : (
                      <ListItem
                        button
                        className={`${classes.navListTitle} MenuItem-${item.id} SideBar-${
                          sideBarIsOpen ? 'open' : 'close'
                        } ${item.activeClass || ''}`}
                        key={`menu-list-item-${item.id}-${index}`}
                        onClick={handleClick(item)}
                        {...((item.id !== 'learning' || true) && { to: item.link })}
                        {...(item.id !== 'learning' && { to: item.link, component: Link })}
                      >
                        {item.menuIcon && (
                          <ListItemIcon
                            className={`${classes.listItemIcon} ${
                              item?.child?.find((e) => e.activeClass === 'active') && !sideBarIsOpen
                                ? 'active'
                                : ''
                            }`}
                          >
                            {item.menuIcon}
                          </ListItemIcon>
                        )}

                        <Typography variant="body1" className="career_portal sideText">
                          {item.title}
                        </Typography>

                        {item.expand ? (
                          <>
                            {item.in ? (
                              <RemoveCircleOutlineIcon
                                onClick={(event) => {
                                  event.stopPropagation();
                                  event.preventDefault();
                                  handleClick(item)(null);
                                }}
                                className={
                                  sideBarIsOpen && item.in
                                    ? `${classes.collapseIconExpand} ${classes.collapseIcon}`
                                    : classes.collapseIcon
                                }
                              />
                            ) : (
                              <AddCircleOutlineIcon
                                onClick={(event) => {
                                  event.stopPropagation();
                                  event.preventDefault();
                                  handleClick(item)(null);
                                }}
                                className={
                                  sideBarIsOpen && item.in
                                    ? `${classes.collapseIconExpand} ${classes.collapseIcon}`
                                    : classes.collapseIcon
                                }
                              />
                            )}
                          </>
                        ) : (
                          <></>
                        )}

                        {item?.dropdown ? (
                          <KeyboardArrowRightIcon
                            className={
                              sideBarIsOpen && item.in ? classes.collapseIcon : classes.collapseIcon
                            }
                          />
                        ) : null}
                      </ListItem>
                    )}
                  </Tooltip>
                  {item.type === 'dropdown' ? (
                    <HoverPopover
                      {...bindPopover(popupState)}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      classes={{ paper: classes.popOutMenu }}
                    >
                      {Boolean(item?.child?.length) && (
                        <List component="div" disablePadding>
                          {item.child.map((child, cIndex) =>
                            child.expand ? (
                              <Box
                                key={`menu-list-child-item-${child.id}-${cIndex}-menu-collapse-list-child-item`}
                              >
                                {sideBarIsOpen && (
                                  <List
                                    component="div"
                                    disablePadding
                                    key={`menu-list-super-child-item-${child.id}-${cIndex}`}
                                  >
                                    {Boolean(child.child.length) &&
                                      child.child.map((c) => (
                                        <Tooltip
                                          title={!sideBarIsOpen ? c.title : ''}
                                          arrow
                                          placement={sideBarIsOpen ? 'bottom' : 'right'}
                                          key={`menu-list-child-item-nested-${child.id}-${c.id}-${cIndex}`}
                                        >
                                          <ListItem
                                            button
                                            className={`${classes.nested} ${classes.navListChildItem} ${c.activeClass} childMenuItem`}
                                            component={Link}
                                            to={c.link}
                                            key={`menu-listItem-child-item-nested-${child.id}-${c.id}-${cIndex}`}
                                            style={c.menuIcon ? {} : { paddingLeft: 64 }}
                                          >
                                            {c.menuIcon && (
                                              <ListItemIcon className={classes.listItemIcon}>
                                                {c.menuIcon}
                                              </ListItemIcon>
                                            )}

                                            <Typography
                                              variant="body1"
                                              className="career_portal sideText"
                                            >
                                              {c.title}
                                            </Typography>
                                          </ListItem>
                                        </Tooltip>
                                      ))}
                                  </List>
                                )}
                              </Box>
                            ) : (
                              <Tooltip
                                title={!sideBarIsOpen ? child.title : ''}
                                arrow
                                placement={sideBarIsOpen ? 'bottom' : 'right'}
                                key={`menu-tooltip-list-item-wrapper-${child.id}-${cIndex}`}
                              >
                                {child?.type && child?.type === 'helper' ? (
                                  <ListItem className={classes.infoItem}>
                                    <Typography variant="body2">{child.title}</Typography>
                                  </ListItem>
                                ) : child?.type && child?.type === 'divider' ? (
                                  <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.23)' }} />
                                ) : (
                                  <ListItem
                                    button
                                    className={`${classes.nested} ${classes.navListChildItem} ${child.activeClass}`}
                                    component={Link}
                                    to={child.link}
                                    key={`menu-list-child-item-${child.id}-${cIndex}`}
                                    style={child.menuIcon ? {} : { paddingLeft: 64 }}
                                    onClick={handleClick(item, child)}
                                    secondaryAction={
                                      <div>
                                        {child.title === 'Due' && (
                                          <Typography
                                            variant="body2"
                                            ml={10.7}
                                            className="career_portal sideText"
                                          >
                                            {taskCount?.inboxCount || 0}
                                          </Typography>
                                        )}
                                        {child.title === 'Upcoming' && (
                                          <Typography
                                            variant="body2"
                                            ml={9}
                                            className="career_portal sideText"
                                          >
                                            {taskCount?.outboxCount || 0}
                                          </Typography>
                                        )}
                                        {child.title === 'Errors' && (
                                          <Typography
                                            variant="body2"
                                            ml={10}
                                            className="career_portal sideText"
                                          >
                                            {taskCount?.Errors || 0}
                                          </Typography>
                                        )}
                                      </div>
                                    }
                                  >
                                    {child.menuIcon && (
                                      <ListItemIcon className={classes.listItemIcon}>
                                        {child.menuIcon}
                                      </ListItemIcon>
                                    )}

                                    <Typography variant="body1" className="career_portal sideText">
                                      {child.title}
                                    </Typography>
                                  </ListItem>
                                )}
                              </Tooltip>
                            ),
                          )}
                        </List>
                      )}
                    </HoverPopover>
                  ) : null}
                  {(!item?.type || item?.type !== 'dropdown') && (
                    <Collapse
                      in={sideBarIsOpen && item.in}
                      timeout="auto"
                      unmountOnExit
                      className={classes.collapseWrapper}
                    >
                      {Boolean(item?.child?.length) && (
                        <List component="div" disablePadding>
                          {item.child.map((child, cIndex) =>
                            child.expand ? (
                              <Box
                                key={`menu-list-child-item-${child.id}-${cIndex}-menu-collapse-list-child-item`}
                              >
                                {sideBarIsOpen && (
                                  <List
                                    component="div"
                                    disablePadding
                                    key={`menu-list-super-child-item-${child.id}-${cIndex}`}
                                  >
                                    {Boolean(child.child.length) &&
                                      child.child.map((c) => (
                                        <Tooltip
                                          title={!sideBarIsOpen ? c.title : ''}
                                          arrow
                                          placement={sideBarIsOpen ? 'bottom' : 'right'}
                                          key={`menu-list-child-item-nested-${child.id}-${c.id}-${cIndex}`}
                                        >
                                          <ListItem
                                            button
                                            className={`${classes.nested} ${classes.navListChildItem} ${c.activeClass} childMenuItem`}
                                            component={Link}
                                            to={c.link}
                                            key={`menu-listItem-child-item-nested-${child.id}-${c.id}-${cIndex}`}
                                            style={c.menuIcon ? {} : { paddingLeft: 64 }}
                                          >
                                            {c.menuIcon && (
                                              <ListItemIcon className={classes.listItemIcon}>
                                                {c.menuIcon}
                                              </ListItemIcon>
                                            )}

                                            <Typography
                                              variant="body1"
                                              className="career_portal sideText"
                                            >
                                              {c.title}
                                            </Typography>
                                          </ListItem>
                                        </Tooltip>
                                      ))}
                                  </List>
                                )}
                              </Box>
                            ) : (
                              <Tooltip
                                title={!sideBarIsOpen ? child.title : ''}
                                arrow
                                placement={sideBarIsOpen ? 'bottom' : 'right'}
                                key={`menu-tooltip-list-item-wrapper-${child.id}-${cIndex}`}
                              >
                                {child?.type && child?.type === 'helper' ? (
                                  <ListItem className={classes.infoItem}>
                                    <Typography variant="body2">{child.title}</Typography>
                                  </ListItem>
                                ) : child?.type && child?.type === 'divider' ? (
                                  <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.23)' }} />
                                ) : (
                                  <ListItem
                                    button
                                    className={`${classes.nested} ${classes.navListChildItem} ${child.activeClass}`}
                                    component={Link}
                                    to={child.link}
                                    key={`menu-list-child-item-${child.id}-${cIndex}`}
                                    style={child.menuIcon ? {} : { paddingLeft: 64 }}
                                    onClick={handleClick(item, child)}
                                    secondaryAction={
                                      <div>
                                        {child.title === 'Due' && (
                                          <Typography
                                            variant="body2"
                                            ml={10.7}
                                            className="career_portal sideText"
                                          >
                                            {taskCount?.inboxCount || 0}
                                          </Typography>
                                        )}
                                        {child.title === 'Upcoming' && (
                                          <Typography
                                            variant="body2"
                                            ml={9}
                                            className="career_portal sideText"
                                          >
                                            {taskCount?.outboxCount || 0}
                                          </Typography>
                                        )}
                                        {child.title === 'Errors' && (
                                          <Typography
                                            variant="body2"
                                            ml={10}
                                            className="career_portal sideText"
                                          >
                                            {taskCount?.Errors || 0}
                                          </Typography>
                                        )}
                                      </div>
                                    }
                                  >
                                    {child.menuIcon && (
                                      <ListItemIcon className={classes.listItemIcon}>
                                        {child.menuIcon}
                                      </ListItemIcon>
                                    )}

                                    <Typography variant="body1" className="career_portal sideText">
                                      {child.title}
                                    </Typography>
                                  </ListItem>
                                )}
                              </Tooltip>
                            ),
                          )}
                        </List>
                      )}
                    </Collapse>
                  )}

                  <Spacer x={1} y={1} />
                </Box>
              ))}
            </List>
            {sideBarIsOpen && activeTrialSubscriptionSubscription?.id && !showUpgradePlan && (
              <Box class={classes.boxWrapper}>
                <Stack gap={'4px'}>
                  <Box class={classes.planText}>Plan: SuperReach Trial </Box>
                  <Box class={classes.planSubText}>
                    Ends in{' '}
                    {calculateDaysDifference(
                      activeTrialSubscriptionSubscription?.next_billing_at || 0,
                    )}
                  </Box>
                </Stack>
                <Box>
                  <Button className={classes.planButton} onClick={() => updateSubscription()}>
                    Upgrade
                  </Button>
                </Box>
              </Box>
            )}
          </Box>

          {/* {isPrimaryMenu && sideBarIsOpen && hasOutreachAccess ? (
            <Box mt={3}>
              <PinnedBoards boards={pinnedBoards} sideBarIsOpen={sideBarIsOpen} />
            </Box>
          ) : null} */}
        </SimpleBar>
        <Box>
          {isDashboard ? (
            <></>
          ) : (
            <>
              {sideBarIsOpen && (
                <Box>
                  <List dense disablePadding>
                    <ListItem dense>
                      <Typography variant="subtitle2" className={classes.copyRightText}>
                        v.{pckg.version} © SuperReach {moment().format('YYYY')}
                        <br />
                        All rights reserved.
                      </Typography>
                    </ListItem>
                  </List>
                </Box>
              )}
            </>
          )}
        </Box>
      </Drawer>

      <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        sx={{
          '& .MuiPaper-root': {
            maxWidth: '560px',
          },
          '& .MuiDialog-paper': {
            borderRadius: '10px',
          },
          '& .MuiDialogContent-root': {
            padding: '0px',
          },
          '& .MuiDialogActions-root ': {
            padding: '0px',
          },
          '& .MuiDialogTitle-root': {
            height: '56px',
            boxSizing: 'border-box',
            '& > div': {
              alignItems: 'baseline',
            },
          },
        }}
      >
        <Box padding={'16px 24px 0px 24px'} display={'flex'}>
          <Box
            sx={{
              color: 'rgba(0, 0, 0, 0.87))',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '0.15px',
              fontFamily: 'Inter',
            }}
          >
            <Typography fontWeight={500}>To upgrade to premium,</Typography>
            <Typography fontWeight={500}>please contact the administrator.</Typography>
          </Box>
          <ModalBgSetup />
        </Box>
      </Modal>
    </div>
  );
};

export default Sidebar;
