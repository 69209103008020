import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  SwipeableDrawer,
  Box,
  Typography,
  ButtonBase,
  IconButton,
  Backdrop,
  Stack,
  Card,
} from '@mui/material';
import { Button, Spacer } from 'src/components/shared';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles, Container, Content, Header, Footer } from './styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import ConfirmDialog from '../../App/ConfirmDialog';

function Drawer({
  open,
  title,
  description,
  onClose,
  onOpen,
  fullWidth,
  buttonText,
  showButton,
  headerAction,
  size,
  disabledPadding,
  showBack,
  onBackClick,
  renderHeaderFunction = null,
  sx,
  headerGap = true,
  children,
  onSave,
  buttonLoading,
  buttonTitle,
  showClose,
  hideHeader,
  contentStyle = {},
  ...props
}) {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);

  const handleClose = () => {
    if (props.showCloseConfirmation) {
      setShowDialog(!showDialog);
    } else {
      onClose();
    }
  };

  const handleConfirm = () => {
    setShowDialog(false);
    onClose();
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      close
      onClose={handleClose}
      onOpen={onOpen}
      disableSwipeToOpen={true}
      classes={{
        paper: classes[`drawer${size.charAt(0).toUpperCase()}${size.slice(1)}`],
      }}
      sx={sx}
      {...props}
    >
      <Container>
        {!hideHeader && (
          <Header className="drawer-header">
            <Box flex={1} display="flex" alignItems="center" flexDirection="row">
              {showBack ? (
                <>
                  <ButtonBase onClick={onBackClick}>
                    <ArrowBackIcon style={{ fill: 'rgba(0,0,0,0.6)' }} />
                  </ButtonBase>
                  <Spacer x={2} y={2} />
                </>
              ) : null}
              <Box width={'100%'}>
                {typeof title === 'string' ? (
                  <Typography
                    variant="h3"
                    color="textPrimary"
                    className={classes.title}
                    gutterBottom={description ? true : false}
                  >
                    {title}
                  </Typography>
                ) : (
                  title
                )}
                {description && (
                  <Typography variant="body2" color="textSecondary" className={classes.subtitle}>
                    {description}
                  </Typography>
                )}
              </Box>
              <Box ml={2}>{renderHeaderFunction()}</Box>
            </Box>
            {headerAction()}
            {headerGap && <Spacer basis={2} />}
            {showClose && (
              <Button style={{ padding: 0 }} iconButton onClick={onClose}>
                <CloseIcon />
              </Button>
            )}
          </Header>
        )}
        <Content style={contentStyle} disabledPadding={disabledPadding}>
          {children}
          <Spacer basis={2} />
        </Content>

        {showDialog && (
          <Backdrop
            sx={{
              position: 'absolute',
              backdropFilter: 'blur(2px)',
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
            // onClick={handleClose}
          >
            <Card
              className="backdrop-content"
              sx={{
                width: 'calc(85% - 80px)',
                padding: '20px',
                backboxcdropFilter: 'blur(3px)',
                position: 'relative',
              }}
            >
              <Stack gap={2}>
                <Typography variant="body1" color="textPrimary">
                  Are you sure you want to close the drawer?
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Your changes will not be saved.
                </Typography>
                <Stack direction={'row'} gap={2} justifyContent={'flex-end'}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="small"
                    style={{ textTransform: 'initial' }}
                    onClick={handleClose}
                  >
                    No
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    style={{ textTransform: 'initial' }}
                    onClick={handleConfirm}
                  >
                    Yes
                  </Button>
                </Stack>
              </Stack>
            </Card>
          </Backdrop>
        )}
      </Container>

      {/* <ConfirmDialog
        open={showDialog}
        title={props.confirmTitle}
        onClose={handleClose}
        onConfirm={handleConfirm}
      >
        {props.confirmDesc}
      </ConfirmDialog> */}
    </SwipeableDrawer>
  );
}

Drawer.propTypes = {
  title: PropTypes.any,
  description: PropTypes.string,
  buttonTitle: PropTypes.string,
  onSave: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  form: PropTypes.string,
  fullWidth: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonLoading: PropTypes.bool,
  showButton: PropTypes.bool,
  headerAction: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  disabledPadding: PropTypes.bool,
  showBack: PropTypes.bool,
  onBackClick: PropTypes.func,
  renderHeaderFunction: PropTypes.func,
  sx: PropTypes.any,
  hideHeader: PropTypes.bool,
  showClose: PropTypes.bool,
  showCloseConfirmation: PropTypes.bool,
  confirmTitle: PropTypes.string,
  confirmDesc: PropTypes.string,
};

Drawer.defaultProps = {
  title: '',
  description: undefined,
  buttonTitle: 'Save',
  onSave: () => {},
  onOpen: () => {},
  onClose: () => {},
  form: '',
  fullWidth: false,
  buttonText: 'save',
  buttonLoading: false,
  showButton: false,
  headerAction: () => null,
  size: 'medium',
  disabledPadding: false,
  showBack: false,
  onBackClick: () => {},
  renderHeaderFunction: () => {},
  sx: {},
  hideHeader: false,
  showClose: true,
  showCloseConfirmation: false,
  confirmTitle: 'Confirmation',
  confirmDesc: 'Are you sure you want to close this drawer?',
};

export default Drawer;
