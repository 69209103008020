import {
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
  Stack,
  ButtonBase,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { capitalizeFirstLetter } from 'src/utils/helper';
import { Button, Modal } from 'src/components/shared';
import images from 'src/config/images';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import BrandVoiceForm from './BrandVoiceForm';
import { useSocketStore, withSocketStore } from '../../../../components/hooks/socketConnector';
import toast from '../../../../utils/toast';
import { useSelector } from 'react-redux';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  flexDirection: 'initial',
  textAlign: 'left',
  color: theme.palette.text.secondary,
  gridRow: '1',
  gridColumn: 'span 3',
  boxShadow:
    '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
  marginBottom: '10px',
  borderRadius: 0,
}));

const menuItems = ['neutral', 'formal', 'informal'];
const checkBoxItems = [
  'empathetic',
  'friendly',
  'professional',
  'funny',
  'authoritative',
  'encouraging',
  'engaging',
  'persuasive',
  'anticipatory',
  'educational',
  'constructive',
  'diplomatic',
];

const CreateVoiceView = (props) => {
  const { emit, aiResponse: socketAiResponse } = useSocketStore();
  const { id } = useParams();

  const tenant = useSelector((state) => state.app.tenant);
  const [selectedItem, setSelectedItem] = useState('neutral');
  const history = useHistory();
  const [showSettings, setShowSettings] = React.useState(false);
  const [showBrandVoiceForm, setShowBrandVoiceForm] = React.useState(false);
  const [createdVoice, setCreatedVoice] = React.useState();
  const [aiResponse, setAiResponse] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [regenerateLoading, setRegenerateLoading] = React.useState(false);

  const [toneOfVoice, setToneOfVoice] = useState({
    name: '',
    sampleText: '',
    params: {
      style: 'neutral',
      tone: [],
    },
  });

  const [errors, setErrors] = useState({
    name: '',
    sampleText: '',
  });

  useEffect(() => {
    setAiResponse(socketAiResponse?.body);
  }, [socketAiResponse]);

  useEffect(() => {
    const fetchVoiceData = async () => {
      try {
        const existingVoice = await props.getVoiceById(id);
        // setToneOfVoice(existingVoice.voice);
        setToneOfVoice({
          name: existingVoice.voice.name,
          sampleText: existingVoice.voice.sampleText,
          params: existingVoice.voice.params,
        });
        setSelectedItem(existingVoice?.voice?.params?.style);
      } catch (error) {
        console.error('Error fetching voice data:', error);
      }
    };
    id && fetchVoiceData();
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setErrors((prevState) => ({
      ...prevState,
      [name]: '',
    }));
    setToneOfVoice((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const updatedTones = checked
      ? [...toneOfVoice.params.tone, name]
      : toneOfVoice.params.tone.filter((tone) => tone !== name);
    setToneOfVoice((prevState) => ({
      ...prevState,
      params: {
        ...prevState.params,
        tone: updatedTones,
      },
    }));
  };

  const handleToggleSettings = () => {
    setShowSettings((prevShowSettings) => !prevShowSettings);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setToneOfVoice((prevState) => ({
      ...prevState,
      params: {
        ...prevState.params,
        style: item,
      },
    }));
  };

  const validateForm = () => {
    let formErrors = { name: '', sampleText: '' };
    let isValid = true;

    if (toneOfVoice.name.trim() === '') {
      formErrors.name = 'Voice Name is required';
      isValid = false;
    }

    if (toneOfVoice.sampleText.trim() === '') {
      formErrors.sampleText = 'Example Content is required';
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setLoading(true);
      try {
        if (id || createdVoice?.id) {
          const updatedVoice = await props.updateVoice({
            id: id || createdVoice?.id,
            data: toneOfVoice,
          });
          if (updatedVoice?.voice?.length > 0) {
            setCreatedVoice(updatedVoice?.voice[0]);
            setAiResponse(updatedVoice?.voice[0]?.voicePrompt || '');
          }
          setShowBrandVoiceForm(true);
          setLoading(false);
          // if (id) {
          //   toast.success('Tone of voice updated successfully');
          // }
        } else {
          setRegenerateLoading(true);
          const createdNewVoice = await props.saveVoice(toneOfVoice);
          setCreatedVoice(createdNewVoice?.voice);
          if (tenant?.featureAccess?.generateToneOfVoiceWithAI) {
            emit(
              'pipeline:execute',
              JSON.stringify({
                slug: 'create-tone-of-voice',
                payload: {
                  text: toneOfVoice?.sampleText,
                },
              }),
            );
            // toast.success('Tone of voice created successfully');
          }
          setLoading(false);
          setShowBrandVoiceForm(true);
          setTimeout(() => {
            setRegenerateLoading(false);
          }, 3000);
        }
      } catch (error) {
        setLoading(false);
        setErrors({ name: error.error.message });
        toast.error(
          id || createdVoice?.id ? 'Tone of voice update failed' : 'Tone of voice create failed',
        );
      }
    }
  };

  const regenerateAIResponse = async () => {
    if (!tenant?.featureAccess?.generateToneOfVoiceWithAI) return null;
    setRegenerateLoading(true);
    emit(
      'pipeline:execute',
      JSON.stringify({
        slug: 'create-tone-of-voice',
        payload: {
          text: toneOfVoice?.sampleText,
        },
      }),
    );
    setTimeout(() => {
      setRegenerateLoading(false);
    }, 3000);
  };

  return (
    <>
      <Box>
        <Box sx={{ pt: '12px', pb: '24px' }}>
          <ButtonBase sx={{ color: '#00000099', padding: 0 }} onClick={() => history.goBack()}>
            <Stack direction={'row'} alignItems={'center'} gap={'16px'}>
              <img src={images.icons.leftNavIcon} alt={''} />
              <Typography
                sx={{
                  color: 'rgba(0, 0, 0, 0.60)',
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '0.4px',
                }}
              >
                Back
              </Typography>
            </Stack>
          </ButtonBase>
        </Box>
        <Item>
          <Typography fontSize="18px" fontWeight={500} color="#000000DE" padding="20px 24px">
            {id ? 'Update a tone of voice' : 'Create a new tone of voice'}
          </Typography>
          <Box padding="30px">
            <Box>
              <Typography fontSize="14px" fontWeight={400} color="#000000DE">
                Tone of voice name
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                padding="0px"
                sx={{
                  mb: '20px',
                  '& .MuiInputBase-root': { height: '44px' },
                }}
                name="name"
                value={toneOfVoice.name}
                onChange={handleInputChange}
                error={Boolean(errors.name)}
                helperText={errors.name}
              />
            </Box>
            <Box>
              <Typography fontSize="14px" fontWeight={400} color="#000000DE">
                Example content
              </Typography>
              <Typography fontSize="12px" fontWeight={400} color="#00000099" padding="12px 0px ">
                Add content that reflects your desired tone of voice. We suggest 50-500 words.
              </Typography>
              <TextField
                fullWidth
                multiline
                maxRows={7}
                variant="outlined"
                placeholder="Example content: blog article, social media posts, company mission, website copy, marketing emails, or any other content that matches your desired voice."
                padding="0px"
                sx={{
                  mb: '16px',
                  '& .MuiInputBase-root': {
                    height: '197px',
                    alignItems: 'flex-start',
                    textAlign: 'start',
                  },
                }}
                name="sampleText"
                value={toneOfVoice.sampleText}
                onChange={handleInputChange}
                error={Boolean(errors.sampleText)}
                helperText={errors.sampleText}
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                fontSize="14px"
                fontWeight={500}
                color="#1976D2"
                sx={{ cursor: 'pointer' }}
                onClick={handleToggleSettings}
              >
                Structured settings &nbsp;
              </Typography>
              {showSettings ? (
                <ArrowUpwardIcon
                  sx={{ color: '#1976D2', width: '22.5px', height: '22.5px' }}
                  onClick={handleToggleSettings}
                />
              ) : (
                <ArrowDownwardIcon
                  sx={{ color: '#1976D2', width: '22.5px', height: '22.5px' }}
                  onClick={handleToggleSettings}
                />
              )}
            </Box>
            {showSettings && (
              <Box mt={2} padding="16px" border="1px solid #E0E0E0" sx={{ borderRadius: '7px' }}>
                <Box>
                  <Typography fontSize="14px" fontWeight={400} marginBottom="8px" color="#00000099">
                    Select style (Optional)
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      border: '1px solid #0000001F',
                      borderRadius: '7px',
                      width: 'fit-content',
                    }}
                  >
                    {menuItems.map((item, index) => (
                      <div
                        key={index}
                        style={
                          item === selectedItem
                            ? {
                                cursor: 'pointer',
                                color: '#1976D2',
                                backgroundColor: '#1976D214',
                                margin: '4px',
                                padding: '8px 16px',
                                borderRadius: '5px',
                                width: '156px',
                                textAlign: 'center',
                                fontWeight: '500',
                              }
                            : {
                                margin: '4px',
                                fontWeight: '400',
                                textAlign: 'center',
                                width: '156px',
                                color: '#00000099',
                                cursor: 'pointer',
                                backgroundColor: '#ffffff',
                                padding: '8px 16px',
                                borderRadius: '4px',
                              }
                        }
                        onClick={() => handleItemClick(item)}
                      >
                        {capitalizeFirstLetter(item)}
                      </div>
                    ))}
                  </Box>
                </Box>
                <Box mt={2}>
                  <Typography fontSize="14px" fontWeight={400} marginBottom="8px" color="#00000099">
                    Select tones (Optional)
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                    {checkBoxItems.map((label, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            checked={toneOfVoice.params.tone.includes(label)}
                            onChange={handleCheckboxChange}
                            name={label}
                          />
                        }
                        label={
                          <Typography fontSize="14px" color={'#666'}>
                            {capitalizeFirstLetter(label)}
                          </Typography>
                        }
                        sx={{ flex: '1 1 calc(100% / 7)' }}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
            )}
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                variant="contained"
                color="secondary"
                endIcon={<ArrowForwardIcon />}
                onClick={() => {
                  handleSubmit();
                }}
                disableElevation
                sx={{
                  padding: '10px 16px',
                }}
                loading={loading}
              >
                <Typography fontSize="16px" fontWeight="500" lineHeight="24px" variant="body">
                  {id ? 'Update tone of voice' : 'Create tone of voice'}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Item>
      </Box>
      <Modal
        open={showBrandVoiceForm === true}
        title={'Your tone of voice'}
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '10px',
            maxWidth: '720px',
          },
          '& .MuiDialogTitle-root': {
            boxSizing: 'border-box',
            padding: '24px',
            '& > div': {
              alignItems: 'baseline',
            },
          },
          '& .MuiDialogActions-root': {
            padding: '0px !important',
          },
        }}
        titleStyle={{
          color: '#000000DE',
          fontSize: '20px',
          fontWeight: 600,
          lineHeight: '23.34px',
        }}
        onClose={() => {
          setShowBrandVoiceForm(false);
        }}
      >
        <BrandVoiceForm
          regenerateLoading={regenerateLoading}
          updateVoice={props?.updateVoice}
          regenerateAIResponse={regenerateAIResponse}
          aiResponse={aiResponse}
          locationId={id}
          createdVoice={createdVoice}
        />
      </Modal>
    </>
  );
};

export default withSocketStore(CreateVoiceView);
