import { all, fork, put, call, takeLatest, select } from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';
import * as sequenceTypes from '../actions/sequenceTypes';
import * as sequenceApi from '../api/sequenceApi';
import * as sequenceActions from '../actions/sequenceActions';
import { toggleAddSequenceForm } from 'src/modules/app/actions/appActions';
import { filterCardKeys } from '../utils/sequenceUtils';
import { fetchEnrollments, fetchEnrollmentsMinimum } from 'src/modules/contacts/api/contactApis';
import { isEmpty } from 'lodash';
import toast from 'src/utils/toast';
import { handleEventsSorting } from '../utils/sequenceUtils';
import { getRelativeDateRange } from 'src/utils/dateUtils';

export const getEvents = (state) => state.sequence.sequenceEvents;
export const getSequences = (state) => state.sequence.sequences;
export const getSequenceTab = (state) => state.sequence.sequenceTab;
export const getShowSequenceForm = (state) => state.app.showSequenceForm;

function* fetchSequences({ paging, filters, tab, sort }) {
  try {
    let filter = {
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      ...(filters?.assignedTo && filters.assignedTo?.id
        ? { createdBy_eq: filters.assignedTo?.id }
        : {}),
      ...(filters?.audienceEmailType && filters?.audienceEmailType.length
        ? {
            audienceEmailType_eq: filters?.audienceEmailType.map((item) => item.value).join(','),
          }
        : {}),
      ...(filters?.accessType && filters?.accessType.length
        ? {
            accessType_eq: filters?.accessType.map((item) => item.value).join(','),
          }
        : {}),
      ...(filters?.createdAtRange
        ? {
            createdAt_gte: filters.createdAtRange.startDate,
            createdAt_lte: filters.createdAtRange.endDate,
          }
        : {}),
      ...filters,
    };
    if (tab && tab !== '' && tab !== 'all') {
      filter.status_eq = tab;
    }
    delete filter?.assignedTo;
    delete filter?.createdAtRange;
    delete filter?.audienceEmailType;
    delete filter?.accessType;
    if (sort && !isEmpty(sort)) {
      filter._sort = `${sort.name}:${sort.direction}`;
    }
    // filter.isDeleted = false;
    const res = yield call(sequenceApi.fetchSequences, filter);
    yield put(sequenceActions.setSequences(res.sequences, res.total));
  } catch (error) {
    yield put(sequenceActions.setSequences([], { value: 0 }));
  }
}

function* postSequence({ sequence, resolve, reject }) {
  const data = { ...sequence };
  const stepsList = sequence?.steps || sequence?.events || [];
  if (data.hasOwnProperty('steps')) delete data.steps;
  if (data.hasOwnProperty('events')) delete data.events;
  yield put(sequenceActions.editOrCreateSequence(true));
  if (data.hasOwnProperty('stopRedirect')) {
    delete data.stopRedirect;
  }
  try {
    const res = yield call(sequenceApi.createSequence, data);
    if (res.error?.message) {
      toast.error(res.error?.message, 'tc');
      if (!sequence?.stopRedirect) yield put(toggleAddSequenceForm());
      return;
    }
    const sequenceData = res.sequence;
    if (!sequence?.stopRedirect) yield put(toggleAddSequenceForm());
    yield put(sequenceActions.editOrCreateSequence(false));
    resolve(res);
    if (data.isAIWriter && stepsList?.length > 0) {
      const updatedStepList = stepsList?.map((item, index) => ({ ...item, eventOrder: index }));
      for (const item of updatedStepList) {
        const payload = { ...item };
        delete payload.autoCloseDuration;
        delete payload.isAutoClose;
        delete payload.isDeleted;
        yield call(sequenceApi.addSequenceStep, sequenceData.id, payload);
      }
      yield call(sequenceApi.addSequenceStep, sequenceData.id, {
        eventType: 'endsquence',
        eventOrder: stepsList?.length,
      });
    }
    let redirectPathname = `/sequence/${sequenceData.id}?onboarding=true`;
    if (data?.isAIWriter) redirectPathname += '&ai=true';
    yield put(replace(redirectPathname));
  } catch (error) {
    console.error(error);
    yield put(sequenceActions.editOrCreateSequence(false));
    reject(error);
  }
}

function* fetchSequence({ seqId }) {
  try {
    let sequence = yield call(sequenceApi.getSequence, seqId);
    sequence = sequence.sequence;
    let events = sequence.events.sort(function (a, b) {
      return a.eventOrder - b.eventOrder;
    });
    events = Object.assign({}, ...events.map((event) => ({ [event.id]: event })));
    yield put(sequenceActions.setSequence(sequence));
  } catch (error) {
    yield put(sequenceActions.setSequence({}));
  }
}

function* fetchSequenceList({ contactId }) {
  try {
    const sequence = yield call(sequenceApi.getSequencesList, contactId);
    yield put(sequenceActions.setSequencesList(sequence));
  } catch (error) {
    yield put(sequenceActions.setSequencesList({}));
  }
}

function* unsubscribeCheck({ contactId }) {
  try {
    const sequence = yield call(sequenceApi.unsubscribeCheck, contactId);
    yield put(sequenceActions.isUnsubscribeSet(sequence));
  } catch (error) {
    yield put(sequenceActions.isUnsubscribeSet({}));
  }
}

function* putSequence({ sequenceId, sequence, resolve, reject, setActiveStep }) {
  yield put(sequenceActions.editOrCreateSequence(true));
  try {
    const updateSequenceData = { ...sequence };
    if (updateSequenceData?.isAIWriter && updateSequenceData?.prevEvents?.length > 0) {
      for (const item of updateSequenceData?.prevEvents) {
        yield call(sequenceApi.deleteSequenceStep, sequenceId, item?.id);
      }
      delete updateSequenceData.prevEvents;
      const stepsList = sequence?.steps || sequence?.events || [];
      if (updateSequenceData.hasOwnProperty('steps')) delete updateSequenceData.steps;
      if (updateSequenceData.hasOwnProperty('events')) delete updateSequenceData.events;
      if (stepsList?.length > 0) {
        const updatedStepList = stepsList?.map((item, index) => ({ ...item, eventOrder: index }));
        for (const item of updatedStepList) {
          const payload = { ...item };
          delete payload.autoCloseDuration;
          delete payload.isAutoClose;
          delete payload.isDeleted;
          yield call(sequenceApi.addSequenceStep, sequenceId, payload);
        }
        yield call(sequenceApi.addSequenceStep, sequenceId, {
          eventType: 'endsquence',
          eventOrder: stepsList?.length,
        });
      }
    }
    const sequencesNew = yield select(getSequences);
    const sequenceTab = yield select(getSequenceTab);
    const res = yield call(sequenceApi.updateSequence, sequenceId, updateSequenceData);
    const updatedSequence = res.sequence;
    const events = updatedSequence?.events.sort(function (a, b) {
      return a.eventOrder - b.eventOrder;
    });
    yield put(sequenceActions.setSequence(updatedSequence));
    yield put(
      sequenceActions.fetchSequences(
        sequencesNew.paging,
        sequencesNew.filters,
        sequenceTab,
        sequencesNew.sort,
      ),
    );
    yield put(sequenceActions.editOrCreateSequence(false));
    toast.success('Sequence updated successfully!');
    if (setActiveStep) setActiveStep?.((prevState) => Number(prevState) + 1);
    resolve(updatedSequence);
  } catch (error) {
    yield put(sequenceActions.editOrCreateSequence(false));
    toast.error('Error occurred while updating sequence. Please try again.');
    reject(error);
  }
}

function* cloneSequence({ sequenceId, payload, resolve, reject }) {
  try {
    const response = yield call(sequenceApi.cloneSequence, sequenceId, payload);
    const sequence = response.clone;
    const formVisible = yield select(getShowSequenceForm);
    if (formVisible === true) {
      yield put(toggleAddSequenceForm());
    }
    // resolve(res);
    yield put(replace(`/sequence/${sequence.id}?onboarding=true`));
  } catch (error) {
    reject(error);
  }
}

function* postSequenceEvent({ sequenceId, payload, resolve, reject }) {
  try {
    const sequencesNew = yield select(getSequences);
    const sequenceTab = yield select(getSequenceTab);
    const res = yield call(sequenceApi.addSequenceStep, sequenceId, payload);
    let events = yield select(getEvents);
    events = Object.values(events);
    events.push(res.events);
    const eventOrder = payload.eventOrder;
    const sortedEvents = yield call(handleEventsSorting, events, res.events, eventOrder);
    yield put(sequenceActions.setSequenceEvents(sortedEvents));

    payload.eventType !== 'endsquence' && toast.success('Sequence step added');
    resolve(res);
  } catch (error) {
    toast.error(error);
    reject(error);
  }
}

function* putSequenceEvent({ sequenceId, stepId, payload, resolve, reject }) {
  try {
    const res = yield call(sequenceApi.editSequenceStep, sequenceId, stepId, payload);
    let events = yield select(getEvents);
    events = Object.values(events);
    events[stepId] = res.event;
    const eventOrder = res.event.eventOrder;
    const sortedEvents = yield call(handleEventsSorting, events, res.event, eventOrder, false);
    yield put(sequenceActions.setSequenceEvents(sortedEvents));
    toast.success('Sequence Step updated successfully!');
    resolve(true);
  } catch (error) {
    toast.error('Error occurred while updating sequence step!');
    reject(false);
  }
}

function* deleteSequenceEvent({ sequenceId, stepId, resolve, reject, hideToaster = false }) {
  try {
    const res = yield call(sequenceApi.deleteSequenceStep, sequenceId, stepId);
    !hideToaster && toast.success('Sequence step removed.');
    let events = res.steps.sort(function (a, b) {
      return a.eventOrder - b.eventOrder;
    });
    events = Object.assign({}, ...events.map((event) => ({ [event.id]: event })));
    yield put(sequenceActions.setSequenceEvents(events));
    resolve(res);
  } catch (error) {
    toast.error('Error occurred while deleting Sequence step. Please try again!');

    reject(error);
  }
}

function* handleEventsReorder({ sequenceId, event, eventOrder }) {
  try {
    let events = yield select(getEvents);
    events = Object.values(events);
    let sortedEvents = [];
    const oldIndex = event.eventOrder;
    const newIndex = eventOrder;
    const totalEvents = events.length;

    if (newIndex == totalEvents - 1) {
    } else {
      const direction = newIndex - oldIndex;

      sortedEvents = [
        ...events
          .filter((item) => {
            return direction > 0
              ? item.eventOrder <= newIndex && item.id !== event.id
              : item.eventOrder < newIndex && item.id !== event.id;
          })
          .map((item) => ({
            ...item,
            eventOrder:
              direction > 0 && item.eventOrder > oldIndex && item.eventOrder <= newIndex
                ? item.eventOrder - 1
                : item.eventOrder,
          })),
        {
          ...event,
          eventOrder,
        },
        ...events
          .filter((item) => {
            return direction > 0
              ? item.eventOrder > newIndex && item.id !== event.id
              : item.eventOrder >= newIndex && item.id !== event.id;
          })
          .map((item) => ({
            ...item,
            eventOrder:
              direction < 0 && item.eventOrder < oldIndex && item.eventOrder >= newIndex
                ? item.eventOrder + 1
                : item.eventOrder,
          })),
      ];

      sortedEvents = Object.assign({}, ...sortedEvents.map((event) => ({ [event.id]: event })));
      yield put(sequenceActions.setSequenceEvents(sortedEvents));
      const payload = {
        eventOrder,
      };
      const res = yield call(sequenceApi.editSequenceStep, sequenceId, event.id, payload);
    }
  } catch (error) {
    toast.error('An error ocurred while sorting events. Please try again.');
  }
}

function* fetchSequencesContacts({ paging, filters, seqId, minimum = false, sort }) {
  try {
    const status = filters?.status || {};
    delete filters.status;
    const openRate = filters?.openRate?.value || undefined;
    let openRateArr = [];
    if (openRate) {
      openRateArr = openRate.split('-');
    }
    delete filters.openRate;

    let filter = {
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      ...(filters?.enrolledBy && filters.enrolledBy?.id
        ? { createdBy_eq: filters.enrolledBy?.id }
        : {}),
      ...(status && status?.value ? { status: status?.value } : {}),
      ...filters,
      ...(openRate && openRateArr.length
        ? {
            openrate_gte: openRateArr[0],
            openrate_lte: openRateArr[1],
          }
        : {}),
    };
    if (sort && !isEmpty(sort)) {
      filter._sort = `${sort.name}:${sort.direction}`;
    }
    if (filters?.enrolledDate && !isEmpty(filters?.enrolledDate)) {
      const dateRange = getRelativeDateRange(filters?.enrolledDate);
      filter = {
        ...filter,
        createdAt_gte: dateRange.start,
        createdAt_lte: dateRange.end,
      };
    }
    delete filter?.enrolledBy;
    delete filter?.enrolledDate;

    Object.keys(filter).forEach((key) => {
      if (filterCardKeys.includes(key) && !filter[key]) {
        delete filter[key];
      }
    });
    // const response = yield call(fetchContacts, filter, seqId);
    const response = yield call(
      !minimum ? fetchEnrollments : fetchEnrollmentsMinimum,
      filter,
      seqId,
    );
    yield put(sequenceActions.setSequencesContacts({ ...response }));
    // yield put(sequenceActions.fetchSequenceStats(seqId));
  } catch (error) {}
}

function* fetchSequenceEvents({ sequenceId }) {
  try {
    let events = yield call(sequenceApi.fetchSequenceSteps, sequenceId);
    events = events.seqSteps;
    events = events.sort(function (a, b) {
      return a.eventOrder - b.eventOrder;
    });
    events = Object.assign({}, ...events.map((event) => ({ [event.id]: event })));
    yield put(sequenceActions.setSequenceEvents(events));
  } catch (error) {}
}

function* fetchSequenceStepGrid({ seqId, data, resolve, reject }) {
  try {
    let events = yield call(sequenceApi.fetchSequenceStepsWithFilter, seqId, data);
    resolve(events);
  } catch (error) {
    reject(error);
  }
}

function* sequenceBulkAction({ action, sequences, payload, resolve, reject }) {
  try {
    let res;
    switch (action) {
      case 'delete':
        const ids = sequences.map((item) => item.id);
        res = yield call(sequenceApi.bulkDeleteSequence, ids);

        toast.success('Sequence(s) deleted!');
        break;

      case 'changeStatus':
        const data = sequences.map((item) => {
          return {
            id: item.id,
            ...payload,
          };
        });
        res = yield call(sequenceApi.bulkUpdateSequence, data);
        toast.success('Sequence(s) status updated!');
        break;

      default:
        break;
    }

    const sequenceTab = yield select(getSequenceTab);
    const sequencesNew = yield select(getSequences);
    yield put(
      sequenceActions.fetchSequences(
        sequencesNew.paging,
        sequencesNew.filters,
        sequenceTab,
        sequencesNew.sort,
      ),
    );

    resolve(res);
  } catch (error) {
    reject(true);
  }
}

function* fetchSequenceStats({ sequenceId }) {
  try {
    const res = yield call(sequenceApi.fetchSequenceStats, sequenceId);
    // let stats = yield select(getSequenceStats);
    // if (res && res.steps && res.steps.length) {
    //   for (let i = 0; i < res.steps.length; i++) {
    //     const step = res.steps[i];
    //     stats[step.eventType] = {
    //       ...stats[step.eventType],
    //       stats: {
    //         ...step.stats,
    //         completed: step?.completed || 0,
    //         pending: step?.pending || 0,
    //         failed: step?.failed || 0,
    //         open: step?.open || 0,
    //         click: step?.click || 0,
    //         reply: step?.reply || 0,
    //         total: step?.total || 0,
    //       },
    //     };
    //   }
    // }
    yield put(sequenceActions.setSequenceStats(res.steps, res.enrollmentsStats));
  } catch (error) {}
}

function* fetchSequencesContactsWithResolveValue({ seqId, search, resolve, reject }) {
  try {
    let filter = {
      _from: 0,
      _size: 50,
    };
    filter._search = search;
    const response = yield call(fetchEnrollments, filter, seqId);
    resolve(response);
  } catch (error) {
    reject(error);
  }
}

export function* watchSagas() {
  yield takeLatest(sequenceTypes.FETCH_SEQUENCES, fetchSequences);
  yield takeLatest(sequenceTypes.POST_SEQUENCE, postSequence);
  yield takeLatest(sequenceTypes.PUT_SEQUENCE, putSequence);
  yield takeLatest(sequenceTypes.FETCH_SEQUENCE, fetchSequence);
  yield takeLatest(sequenceTypes.CLONE_SEQUENCE, cloneSequence);
  yield takeLatest(sequenceTypes.POST_SEQUENCE_EVENT, postSequenceEvent);
  yield takeLatest(sequenceTypes.PUT_SEQUENCE_EVENT, putSequenceEvent);
  yield takeLatest(sequenceTypes.FETCH_SEQUENCES_LIST, fetchSequenceList);
  yield takeLatest(sequenceTypes.IS_UNSUBSCRIBE_CHECK, unsubscribeCheck);
  yield takeLatest(sequenceTypes.DELETE_SEQUENCE_EVENT, deleteSequenceEvent);
  yield takeLatest(sequenceTypes.HANDLE_EVENTS_REORDER, handleEventsReorder);

  yield takeLatest(sequenceTypes.FETCH_SEQUENCE_CONTACTS, fetchSequencesContacts);
  yield takeLatest(
    sequenceTypes.FETCH_SEQUENCE_CONTACTS_RESOLVE,
    fetchSequencesContactsWithResolveValue,
  );
  yield takeLatest(sequenceTypes.FETCH_SEQUENCE_EVENTS, fetchSequenceEvents);
  yield takeLatest(sequenceTypes.SEQUENCE_BULK_ACTIONS, sequenceBulkAction);
  yield takeLatest(sequenceTypes.FETCH_SEQUENCE_STATS, fetchSequenceStats);
  yield takeLatest(sequenceTypes.FETCH_SEQUENCE_STATS_GRID, fetchSequenceStepGrid);
}

export default function* runSagas() {
  yield all([fork(watchSagas)]);
}
