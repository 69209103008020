import React from 'react';
import { Box, Stack } from '@mui/material';
import images from 'src/config/images';
import { ContentDescription, ContentHeader } from './styles';
import { BottomButton } from './utils';

const DownloadChromeExtensionView = ({
  userJourney,
  setUserJourney,
  handleUpdateUserJourney,
  loading,
}) => {
  return (
    <>
      <Stack gap={'8px'}>
        <ContentHeader>Download the chrome extension</ContentHeader>
        <ContentDescription>
          Save hours per day ! Discover prospects' contact details, engage them with powerful
          multi-channel messaging, and effortlessly sync everything with your CRM
        </ContentDescription>
      </Stack>
      <Box sx={{ textAlign: 'center', mb: '40px', mt: '40px' }}>
        <img src={images.journey.downloadChromExtension} />
      </Box>
      <BottomButton
        nextButtonTitle={'Download chrome'}
        loading={loading}
        onSkip={() => {
          handleUpdateUserJourney(userJourney, 'skipped', 'experience');
        }}
        onNext={() => {
          handleUpdateUserJourney(userJourney, 'completed', 'experience');
          window.open(
            'https://chrome.google.com/webstore/detail/superreach-sales-hiring-a/nfjemjifleiijedpmipbgbpefcopdkci?hl=en',
          );
        }}
      />
    </>
  );
};
export default DownloadChromeExtensionView;
