import React, { useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, Grid, Container, Card, CardContent, Tooltip } from '@mui/material';
import { Tabs, Breadcrumbs, Empty } from 'src/components/App';
import { Button, Spacer, Drawer, Modal } from 'src/components/shared';
import SequenceOnboarding from '../../components/Sequence/SequenceOnboarding';
import SequenceSteps from '../../components/Sequence/SequenceSteps';
import SettingsForm from '../../components/Sequence/SettingsForm';
import SequenceStatusForm from '../../components/Sequence/SequenceStatusForm';
import SequenceStatusFormSwitch from '../../components/Sequence/SequenceStatusFormSwitch';
import CloneSequenceForm from '../../components/Sequence/CreateSequence/CloneSequenceForm';
import EnrollContactsForm from '../../components/Sequence/EnrollContactsForm';

import CopyAllIcon from '@mui/icons-material/CopyAll';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PreviewIcon from '@mui/icons-material/Preview';
import EditIcon from '@mui/icons-material/Edit';

import SequenceContacts from '../../components/SequenceContacts/Grid';
import SequenceSummary from '../../components/SequenceSummary';
import SequencePreview from '../../components/Sequence/SequencePreview';

import { sequenceDetailsTabs } from '../../config';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

function SequenceView({ integrations, sequence, fields, sequenceEvents, ...props }) {
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [isAiSeqGenerator, setIsAiSeqGenerator] = useState(false);
  const [showCloneForm, setShowCloneForm] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showAddContactsForm, setShowAddContactsForm] = useState(false);

  const [currentTab, setCurrentTab] = useState(sequenceDetailsTabs[0].id);
  const history = useHistory();

  useEffect(() => {
    if (props.location && props.location?.search) {
      const searchParams = new URLSearchParams(props.location.search);
      const onboardingValue = searchParams.get('onboarding');
      if (onboardingValue) {
        setShowOnboarding(true);
      }
    }
    if (props?.match?.params?.tab && props?.match?.params?.tab !== '') {
      setCurrentTab(props?.match?.params?.tab);
    }
  }, [props]);

  const togglePreview = () => {
    setShowPreview(!showPreview);
  };

  const breadcrumbs = useMemo(
    () => [
      {
        title: 'Sequence',
        route: '/sequences',
      },
      {
        title: sequence?.name || '',
      },
    ],
    [sequence],
  );

  const handleCloneSequence = () => {
    setShowCloneForm(true);
  };

  const onTabChange = (tab) => {
    // setCurrentTab(tab.id);
    history.push(`/sequence/${sequence.id}/${tab.id}`);
  };

  const onSequenceUpdate = (data) => {};

  const fetchSequenceContacts = (paging, filters, seqId, sort) => {
    if (seqId) {
      return props.fetchSequenceContacts(paging, filters, seqId, sort);
    }
  };

  const renderTab = () => {
    switch (currentTab) {
      case 'summary':
        return (
          <SequenceSummary
            sequence={sequence}
            seqId={sequence.id}
            fetchSequenceContacts={fetchSequenceContacts}
            sequencesContacts={props.sequencesContacts}
            contactsLoading={props.contactsLoading}
            sequencesStats={props.sequencesStats}
            sequenceStatsLoading={props.sequenceStatsLoading}
            enrollmentsStats={props.enrollmentsStats}
            fetchSequenceStats={props.fetchSequenceStats}
          />
        );
        break;

      case 'people':
        return (
          <SequenceContacts
            seqId={sequence.id}
            sequence={sequence}
            fetchSequenceContacts={fetchSequenceContacts}
            sequencesContacts={props.sequencesContacts}
            contactsLoading={props.contactsLoading}
            deleteContact={props?.deleteContact}
            toggleAddContactForm={props?.toggleAddContactForm}
            user={props.user}
            contactBulkAction={props.contactBulkAction}
            fetchSeqDashboardsReport={props.fetchSeqDashboardsReport}
            integrations={props.integrations?.data || []}
          />
        );
        break;
      case 'steps':
        return (
          <Container maxWidth="md">
            <SequenceSteps
              sequence={sequence}
              sequenceEvents={sequenceEvents}
              {...props}
              loading={props.loadingEvents}
            />
          </Container>
        );
        break;
      case 'preview':
        return (
          <SequencePreview
            sequence={sequence}
            sequenceEvents={sequenceEvents}
            seqId={sequence.id}
            putSequenceEvent={props?.putSequenceEvent}
            // onClose={togglePreview}
            fetchSequenceContactsResolve={props.fetchSequenceContactsResolve}
            fetchSequenceEvents={props.fetchSequenceEvents}
          />
        );
      case 'settings':
      default:
        return (
          <Container maxWidth="lg" mt={2}>
            <SettingsForm
              integrations={integrations}
              sequence={sequence}
              sequenceEvents={sequenceEvents}
              onSubmit={onSequenceUpdate}
              fields={fields}
              isOnboarding={false}
              moveToNext={() => {}}
              {...props}
            />
          </Container>
        );
        break;
    }
  };

  const closeOnboarding = () => {
    props?.setSequence();
    // history.replace(`/sequence/${sequence.id}`);
    setShowOnboarding(false);
    history.replace(`/sequences`);
  };

  return (
    <>
      {sequence?.id ? (
        <>
          <Box>
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Box
                  display="flex"
                  flex={1}
                  sx={{ alignItems: 'center', cursor: 'pointer' }}
                  onClick={() => history.replace('/sequences')}
                >
                  <ChevronLeftIcon
                    sx={{ color: '#00000061', cursor: 'pointer', marginLeft: '-8px' }}
                  />
                  <Typography variant="caption" sx={{ color: '#00000061', fontWeight: 400 }}>
                    Back
                  </Typography>
                </Box>
                {/*<Breadcrumbs steps={breadcrumbs} />*/}
                <Spacer y={1.2} />
                <Box display="flex" flex={1} sx={{ alignItems: 'center', gap: '10px' }}>
                  <Typography
                    variant="h1"
                    sx={{ fontSize: '18px', color: '#000000DE', lineHeight: 'unset' }}
                  >
                    {sequence?.name}
                  </Typography>
                  {currentTab !== 'settings' && (
                    <EditIcon
                      onClick={() => onTabChange({ id: 'settings' })}
                      sx={{ height: '18px', width: '18px', color: '#00000042', cursor: 'pointer' }}
                    />
                  )}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                  <SequenceStatusFormSwitch sequence={sequence} putSequence={props.putSequence} />
                  <Box mt={1.5} display="flex">
                    <Tooltip title="Clone sequence">
                      <div>
                        <Button
                          iconButton
                          size="small"
                          onClick={handleCloneSequence}
                          color="primary"
                          variant="contained"
                        >
                          <CopyAllIcon />
                        </Button>
                      </div>
                    </Tooltip>
                    <Spacer x={0.6} y={0.6} />
                    <Tooltip title="Enrol contacts">
                      <div>
                        <Button
                          iconButton
                          size="small"
                          onClick={() => {
                            setShowAddContactsForm(true);
                          }}
                          color="primary"
                          variant="contained"
                        >
                          <GroupAddIcon />
                        </Button>
                      </div>
                    </Tooltip>
                  </Box>
                  {/* <Button variant="contained" color="secondary">
                Actions
              </Button> */}
                </Box>
              </Grid>
            </Grid>
            <Spacer y={2} />
            <Tabs
              tabs={sequenceDetailsTabs?.map((ele) => {
                if (ele.id === 'people')
                  return {
                    ...ele,
                    name: `Contacts (${sequence?.stats?.totalEnrolments || 0})`,
                  };
                else return ele;
              })}
              onChange={onTabChange}
              value={currentTab}
            />
          </Box>
          <Box mt={4} mb={4}>
            {renderTab()}
          </Box>
        </>
      ) : (
        <Empty
          title="No sequence found"
          description="create a new sequence"
          buttonTitle="Create sequence"
          onClick={props.toggleAddSequenceForm}
        />
      )}

      {/*{showPreview && (*/}
      {/*  <SequencePreview*/}
      {/*    sequence={sequence}*/}
      {/*    sequenceEvents={sequenceEvents}*/}
      {/*    onClose={togglePreview}*/}
      {/*    fetchSequenceEvents={props.fetchSequenceEvents}*/}
      {/*  />*/}
      {/*)}*/}

      <Modal fullScreen={true} open={showOnboarding} onClose={() => {}} style={{ zIndex: 1199 }}>
        <SequenceOnboarding onClose={closeOnboarding} />
      </Modal>

      <Modal
        title="Clone Sequence"
        size="xs"
        open={showCloneForm}
        onClose={() => {
          setShowCloneForm(false);
        }}
      >
        <CloneSequenceForm
          cloneSequence={props.cloneSequence}
          disabled={true}
          sequence={sequence}
        />
      </Modal>

      <Drawer
        open={showAddContactsForm}
        title="Enrol contacts to sequence"
        onClose={() => {
          setShowAddContactsForm(false);
        }}
      >
        <EnrollContactsForm
          sequence={sequence}
          fetchSequenceContacts={fetchSequenceContacts}
          sequencesContacts={props.sequencesContacts}
          fetchSequence={props.fetchSequence}
          onClose={() => {
            setShowAddContactsForm(false);
          }}
        />
      </Drawer>
    </>
  );
}

export default SequenceView;
