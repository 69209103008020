import React, { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import styled from 'styled-components';

import { getCurrentUserJourneyStep } from 'src/utils/helper';

import {
  DownloadChromeExtensionView,
  GoalsView,
  WelcomeScreen,
  ExperienceView,
  GetStarted,
} from './Journey';
import { CopyRight } from 'src/layouts/styles';
import images from 'src/config/images';
import JourneyStepper from '../../../../components/shared/Stepper/JourneyStepper';
import { updateSignupjourney } from '../../../auth/api/authApis';

const stepData = {
  welcomeScreen: 0,
  downloadChrome: 1,
  experience: 2,
  goals: 3,
  getStarted: 4,
};

const hasStepper = ['downloadChrome', 'experience', 'goals', 'getStarted'];
const hideLogo = ['experience'];

const JourneySteps = {
  welcomeScreen: WelcomeScreen,
  downloadChrome: DownloadChromeExtensionView,
  experience: ExperienceView,
  goals: GoalsView,
  getStarted: GetStarted,
};

const OutReachJourneyView = (props) => {
  const { userJourney, setUserJourney } = props;
  const [currentJourneyStep, setCurrentJourneyStep] = useState('');
  const [step, setStep] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const stepKey = getCurrentUserJourneyStep(userJourney);
    if (stepKey) {
      if (stepKey === 'allDone') {
        props.history.push('/outreachvideolibrary');
        return;
      }
      setCurrentJourneyStep(stepKey);
      setStep(stepData[stepKey] || 0);
    }
  }, [props.userJourney]);

  const handleUpdateUserJourney = async (jourenyData, data, nextScreen) => {
    const payload = {
      ...jourenyData,
      onboardingSteps: { ...jourenyData?.onboardingSteps, [currentJourneyStep]: data },
    };
    setLoading(true);
    if (!nextScreen) {
      payload.onboardingCompleted = true;
    }
    try {
      await updateSignupjourney(payload);
      setUserJourney(payload);
      if (!nextScreen) {
        props.history.push('/outreachvideolibrary');
      }
      setCurrentJourneyStep(nextScreen);
      setStep(step + 1);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const JourneyStep = JourneySteps[currentJourneyStep];

  return (
    <StyledMain>
      <StyledContent>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ textAlign: 'center', mb: '36px' }}>
            <img src={images.app.logo} alt="" />
          </Box>
          {hasStepper.includes(currentJourneyStep) && (
            <JourneyStepper
              sx={{ marginBottom: '32px !important' }}
              totalSteps={4}
              currentStep={step - 1}
            />
          )}
          {JourneyStep && (
            <JourneyStep
              loading={loading}
              handleUpdateUserJourney={handleUpdateUserJourney}
              userJourney={props?.userJourney}
              setUserJourney={props?.setUserJourney}
            />
          )}
        </Box>
        <CopyRight>© SuperReach {new Date().getFullYear()} - All rights reserved.</CopyRight>
      </StyledContent>
    </StyledMain>
  );
};

export default OutReachJourneyView;

const StyledMain = styled(Stack)`
  height: 100vh;
  max-width: 500px;
  margin: 0 auto;
`;

const StyledContent = styled(Stack)`
  margin-top: 100px;
  margin-bottom: 30px;
  justify-content: space-between;
  height: 100%;
  align-items: center;
`;
