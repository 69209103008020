import React from 'react';
import { Stack } from '@mui/material';
import {
  ContentDescription,
  ContentHeader,
  ExperienceButton,
  SubContentDescription,
} from './styles';
import { BottomButton } from './utils';

const GoalsView = ({ handleUpdateUserJourney, setUserJourney, userJourney }) => {
  const [goals, setGoals] = React.useState(userJourney?.goals ? [...userJourney?.goals] : []);

  const handleOnClick = (goal) => {
    const tempGoals = [...goals];
    if (tempGoals.includes(goal)) {
      tempGoals.splice(tempGoals.indexOf(goal), 1);
    } else if (tempGoals.length < 3) {
      tempGoals.push(goal);
    }
    setGoals(tempGoals);
  };

  return (
    <>
      <Stack gap={'36px'}>
        <ContentHeader>Your Goals</ContentHeader>
        <SubContentDescription sx={{ textAlign: 'start !important' }}>
          Select your top 3 priorities
        </SubContentDescription>
      </Stack>
      <Stack mt={'24px'} gap={'16px'}>
        <ExperienceButton
          blackButton={goals.includes('findEmails')}
          onClick={() => handleOnClick('findEmails')}
          size={'large'}
          variant="contained"
        >
          Find emails
        </ExperienceButton>
        <ExperienceButton
          blackButton={goals.includes('findNumbers')}
          onClick={() => handleOnClick('findNumbers')}
          size={'large'}
          variant="contained"
        >
          Find numbers
        </ExperienceButton>
        <ExperienceButton
          blackButton={goals.includes('increaseConversation')}
          onClick={() => handleOnClick('increaseConversation')}
          size={'large'}
          variant="contained"
        >
          Increase Conversation
        </ExperienceButton>
        <ExperienceButton
          blackButton={goals.includes('reduceAdmin')}
          onClick={() => handleOnClick('reduceAdmin')}
          size={'large'}
          variant="contained"
        >
          Reduce Admin
        </ExperienceButton>
        <ExperienceButton
          blackButton={goals.includes('manageSalesProcess')}
          onClick={() => handleOnClick('manageSalesProcess')}
          size={'large'}
          variant="contained"
        >
          Manage Sales Process
        </ExperienceButton>
      </Stack>
      <BottomButton
        nextDisabled={goals.length < 3}
        onSkip={() => {
          setUserJourney((prevState) => ({ ...prevState, goals: goals }));
          handleUpdateUserJourney({ ...userJourney, goals: goals }, 'skipped', 'getStarted');
        }}
        onNext={() => {
          setUserJourney((prevState) => ({ ...prevState, goals: goals }));
          handleUpdateUserJourney({ ...userJourney, goals: goals }, 'completed', 'getStarted');
        }}
      />
    </>
  );
};
export default GoalsView;
