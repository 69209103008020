import * as React from 'react';
import { Box, Button } from '@mui/material';
import {useStyles} from "./styles";

const seqTypeList = [
  {
    label: 'Client',
    value: 'work',
  },
  {
    label: 'Candidate',
    value: 'personal',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export default function SeqTypeTab({ value, onClick }) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {seqTypeList?.map((item) => (
        <>
          <Button
            onClick={() => onClick(item?.value)}
            className={`${classes.button} ${value === item?.value && classes.activeButton}`}
          >
            {item?.label}
          </Button>
        </>
      ))}
    </Box>
  );
}
