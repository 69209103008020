import { makeStyles } from '@mui/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export const useStyles = makeStyles(({}) => ({
  root: {
    borderRadius: '7px',
    border: '1px solid #0000001F',
    display: 'flex',
    padding: '4px',
    alignItems: 'center',
    gap: '8px',
    flex: '1 0 0',
  },
  button: {
    display: 'flex',
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '5px',
    color: '#00000099',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    textAlign: 'center',
    fontStyle: 'normal',
    background: 'transparent',
    textTransform: 'none',
  },
  activeButton: {
    background: '#1976D214',
    color: '#1976D2',
    fontWeight: 500,
    lineHeight: '20px',
  },
  label: {
    color: '#00000099',
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '24px',
  },
  card: {
    borderRadius: '15px',
    background: '#FFF',
    display: ' flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  tootltipHeader: {
    color: '#FFF',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '23.34px',
  },
  tootltipContent: {
    color: '#FFF',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    marginTop: '20px',
  },
}));

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  transform: 'translate(138px, -457px) !important',
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#082037',
    boxShadow: 'none',
    padding: '40px',
    minWidth: '378px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#082037',
    width: '48px',
    height: '24px',
    bottom: '-16px !important',
    '&::before': {
      borderWidth: '12px 24px',
      margin: '0px',
    },
  },
}));
