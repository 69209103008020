import React, { useEffect, useMemo, useState } from 'react';

import {
  Grid,
  Box,
  Typography,
  CircularProgress,
  Backdrop,
  Stack,
  Select,
  Dialog,
  MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Form } from 'src/components/shared';
import * as Yup from 'yup';

import SeqTypeTab from './SeqTypeTab';
import { useStyles } from './styles';
import images from 'src/config/images';
import AIPromptView from './AIPromptView';
import { useSelector } from 'react-redux';
import { fetchBrandVoice } from '../../../api/sequenceApi';
import toast from '../../../../../utils/toast';
import { useSocketStore, withSocketStore } from '../../../../../components/hooks/socketConnector';

const emailTypes = ['General Email', 'Client - CV Spec'];

const AIButton = ({ classes, label, step, onClick, loading }) => {
  return (
    <Button
      className={classes.aiButton}
      form="ai-writer-form"
      loading={loading}
      type={'submit'}
      onClick={onClick}
    >
      <img src={images.icons.magicIcon} /> {label}
    </Button>
  );
};

const AIInsertButton = ({ classes, label, step, onClick, disabled }) => {
  return (
    <Button className={classes.aiInsertButton} disabled={disabled} onClick={onClick}>
      <img src={images.icons.checkIcon} /> {label}
    </Button>
  );
};

const AIWriterFormHeader = ({
  classes,
  step,
  showSelect,
  selectedEmailFormType,
  setSelectedEmailFormType,
  ...props
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{ padding: '16px 24px' }}
      >
        <Stack direction={'row'} alignItems={'center'} gap={step === 0 ? '24px' : '16px'}>
          {step === 1 ? (
            <img
              src={images.icons.backIcon}
              onClick={() => props?.setStep(0)}
              style={{ cursor: 'pointer' }}
              alt={''}
            />
          ) : null}
          <Typography className={classes.headerText}>
            {step === 0 ? 'AI Writer' : 'Back to AI Prompt'}
          </Typography>
          {step === 0 && props?.formType === 'email' ? (
            <Select
              variant={'outlined'}
              fullWidth
              sx={{
                width: '204px',
                color: '#1976D2',
                background: '#1976D214',
                '& .MuiOutlinedInput-input': {
                  padding: '6px 4px 6px 16px',
                  fontSize: '14px',
                },
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                  border: 0,
                },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 0,
                },
              }}
              isSmall
              value={selectedEmailFormType}
              onChange={(e) => {
                setSelectedEmailFormType(e?.target?.value);
              }}
              options={emailTypes}
            >
              <MenuItem value={'generalEmail'}>General email</MenuItem>
              <MenuItem value={'clientCVspec'}>Client - CV spec</MenuItem>
            </Select>
          ) : null}
        </Stack>
        <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => props?.handleClose()} />
      </Stack>
    </Box>
  );
};

function AIWriteForm(props) {
  if (!props?.open) return <></>;
  const { emit, aiResponse: socketAiResponse } = useSocketStore();

  const classes = useStyles();
  const [step, setStep] = React.useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [aiResponse, setAIResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedEmailFormType, setSelectedEmailFormType] = useState('generalEmail');
  const [formValues, setFormValues] = useState({
    type: 'Client',
    furtherInstruction: '',
    purpose: '',
    toneOfVoice: {},
  });
  const [regeneratedValueIndex, setRegeneratedValueIndex] = useState(null);
  const aiFormFieldsWithValue = useSelector((state) => state.app.globals?.aiFormFieldsWithValue);

  useEffect(() => {
    if (regeneratedValueIndex) {
      aiResponse[regeneratedValueIndex] = socketAiResponse;
      setAIResponse([...aiResponse]);
    } else {
      setAIResponse([socketAiResponse]);
    }
  }, [socketAiResponse]);

  const aiType = useMemo(() => {
    if (aiFormFieldsWithValue?.Type) {
      return aiFormFieldsWithValue?.Type.map((type) => {
        return {
          label: type,
          value: type,
        };
      });
    }
    return [];
  }, [aiFormFieldsWithValue?.Type]);

  const aiTonality = useMemo(() => {
    if (aiFormFieldsWithValue?.Tonality) {
      return aiFormFieldsWithValue?.Tonality.map((type) => {
        return {
          label: type,
          value: type,
        };
      });
    }
    return [];
  }, [aiFormFieldsWithValue?.Tonality]);

  const getPurposeOptions = (values) => {
    if (aiFormFieldsWithValue?.Purpose) {
      return aiFormFieldsWithValue?.Purpose?.find(
        (item) => item?.optionValue === values?.type,
      )?.fields?.map((item) => ({ label: item, value: item }));
    }
    return [];
  };

  const handleClose = () => {
    setStep(0);
    setSelectedOption(null);
    props?.setOpenAIWriter(false);
  };

  const aiGenerationType = {
    email: 'Email',
    linkedin: 'Linkedin Messages',
    linkedinMail: 'Linkedin Inmail',
    linkedinConnection: 'LinkedIn Connection',
  };

  const generateAIContent = async (values) => {
    setLoading(true);
    setFormValues({ ...values });
    const finalValue = { ...values };
    if (values?.toneOfVoice?.name) {
      finalValue.toneOfVoice = [values?.toneOfVoice?.name];
    } else {
      finalValue.toneOfVoice = [];
    }
    finalValue.aiGeneratingType = aiGenerationType[props?.formType];
    try {
      emit(
        'pipeline:execute',
        JSON.stringify({
          slug: props?.formType === 'email' ? 'create-email' : 'create-linkedin-message',
          payload: { ...finalValue },
        }),
      );
      setStep(1);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } catch (e) {
      toast.error('AI generation failed', 'tc');
    }
  };

  const regenerateContent = async (index) => {
    setRegeneratedValueIndex(index);
    setLoading(true);
    const finalValue = { ...formValues };
    if (formValues?.toneOfVoice?.name) {
      finalValue.toneOfVoice = [formValues?.toneOfVoice?.name];
    }
    finalValue.aiGeneratingType = aiGenerationType[props?.formType];
    emit(
      'pipeline:execute',
      JSON.stringify({
        slug: props?.formType === 'email' ? 'create-email' : 'create-linkedin-message',
        payload: { ...finalValue },
      }),
    );
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  const insertSelectedTemplate = () => {
    props?.setSelectedAiResponse(aiResponse[selectedOption]);
    props?.setOpenAIWriter(false);
  };

  return (
    <>
      {props?.loading ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={props?.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
      <Dialog
        sx={{
          maxWidth: '900px',
          width: '100%',
          left: { xs: '3%', lg: '23%' },
        }}
        PaperProps={{
          sx: {
            borderRadius: '15px',
            maxWidth: '900px',
            width: '100%',
          },
        }}
        maxWidth="md"
        open={props?.open}
        disableEscapeKeyDown
        hideBackdrop
        onClose={handleClose}
      >
        <Box sx={{ overflowY: 'auto' }}>
          <Box>
            <AIWriterFormHeader
              handleClose={handleClose}
              step={step}
              showSelect={props?.showSelect}
              setStep={setStep}
              classes={classes}
              selectedEmailFormType={selectedEmailFormType}
              setSelectedEmailFormType={setSelectedEmailFormType}
              {...props}
            />
            <Box className={classes.cardContent}>
              {step === 0 ? (
                <Form
                  initialValues={{
                    type: formValues?.type || '',
                    purpose: formValues?.purpose || '',
                    toneOfVoice: formValues?.toneOfVoice || {},
                    furtherInstruction: formValues?.furtherInstruction || '',
                  }}
                  validationSchema={Yup.object().shape({
                    type: Yup.string().required('Type is required'),
                    purpose: Yup.string().when('type', {
                      is: (type) => {
                        return props?.formType !== 'email';
                      },
                      then: Yup.string().required('Please select purpose'),
                      otherwise: Yup.string(),
                    }),
                  })}
                  enableReinitialize={true}
                  onSubmit={generateAIContent}
                >
                  {({ values, ...formProps }) => {
                    return (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          formProps.submitForm();
                          return false;
                        }}
                        id="ai-writer-form"
                        style={{ minHeight: '365px', height: '100%', width: '100%' }}
                        noValidate
                      >
                        <Box
                          display="flex"
                          flex={1}
                          flexDirection="column"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          height="100%"
                        >
                          <Box width="100%">
                            <Grid container sx={{ marginTop: '16px' }}>
                              {props?.showSelect && selectedEmailFormType === 'clientCVspec' ? (
                                <>
                                  <Grid item xs={2} container alignItems="center">
                                    <Typography className={classes.label}>
                                      Candidate name
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={10}>
                                    <Form.Field.Select
                                      options={[]}
                                      placeholder={'Please select'}
                                      fullWidth
                                      size="small"
                                      variant="outlined"
                                      name="candidateName"
                                      // label="Category"
                                      optLabel="label"
                                      optValue="value"
                                      isSmall
                                    />
                                  </Grid>

                                  <Grid item xs={2} container alignItems="center">
                                    <Typography className={classes.label}>
                                      Candidate info
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={10}>
                                    <Form.Field.Input
                                      fullWidth
                                      variant="outlined"
                                      name="candidateInfo"
                                      label="Candidate info"
                                      style={{ marginBottom: '0px' }}
                                      size="small"
                                      isSmall
                                    />
                                  </Grid>

                                  <Grid item xs={2} container alignItems="center">
                                    <Typography className={classes.label}>Content style</Typography>
                                  </Grid>
                                  <Grid item xs={10}>
                                    <Form.Field.Select
                                      options={[]}
                                      placeholder={'Please select'}
                                      fullWidth
                                      size="small"
                                      variant="outlined"
                                      name="contentStyle"
                                      // label="Category"
                                      optLabel="label"
                                      optValue="value"
                                      isSmall
                                    />
                                  </Grid>
                                </>
                              ) : (
                                <>
                                  <Grid item xs={2} container alignItems="center">
                                    <Typography className={classes.label}>
                                      {props?.formType === 'email' ? ' Email Type *' : 'Type *'}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={10}>
                                    <SeqTypeTab
                                      value={values?.type}
                                      onClick={async (value) => {
                                        await formProps?.setFieldValue('type', value);
                                        await formProps?.setFieldValue('purpose', '');
                                      }}
                                      formProps={formProps}
                                      seqTypeList={aiType}
                                      error={formProps?.errors?.type}
                                    />
                                  </Grid>

                                  <Grid item xs={2} container alignItems="center">
                                    <Typography className={classes.label}>
                                      {props?.formType === 'email' ? ' Email Purpose' : 'Purpose *'}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={10}>
                                    <Form.Field.Select
                                      disabled={!values?.type}
                                      options={getPurposeOptions(values)}
                                      placeholder={'Please select'}
                                      fullWidth
                                      size="small"
                                      variant="outlined"
                                      name="purpose"
                                      // label="Category"
                                      optLabel="label"
                                      isSmall
                                      optValue="value"
                                    />
                                  </Grid>
                                </>
                              )}
                              <Grid item xs={2} container alignItems="center">
                                <Typography className={classes.label}>
                                  {props?.formType === 'email' ? 'Tone of voice' : 'Tonality'}
                                </Typography>
                              </Grid>
                              <Grid item xs={10}>
                                {/*<Form.Field.AutoComplete*/}
                                {/*  multiple={false}*/}
                                {/*  fullWidth*/}
                                {/*  options={[]}*/}
                                {/*  variant="outlined"*/}
                                {/*  remoteMethod={(search) => {*/}
                                {/*    return fetchTimezoneLookup(search);*/}
                                {/*  }}*/}
                                {/*  name="timezone"*/}
                                {/*  label="Timezone"*/}
                                {/*  optLabel="name"*/}
                                {/*  optValue="id"*/}
                                {/*  tip="Change timezone for all selected users."*/}
                                {/*/>*/}

                                <Form.Field.AutoComplete
                                  // options={aiTonality}
                                  options={[]}
                                  fullWidth
                                  variant="outlined"
                                  name="toneOfVoice"
                                  placeholder={'Please select'}
                                  // label="Please select"
                                  optValue="id"
                                  optLabel="name"
                                  // isSmall
                                  remoteMethod={(val) => fetchBrandVoice(val)}
                                  size={'small'}
                                  InputLabelProps={{
                                    sx: {
                                      fontSize: '14px',
                                    },
                                  }}
                                />
                              </Grid>

                              <Grid item xs={12} container alignItems="center" sx={{ mt: '11px' }}>
                                <Typography className={classes.label}>
                                  Further instructions
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Form.Field.Input
                                  fullWidth
                                  multiline
                                  rows={14}
                                  variant="outlined"
                                  name="furtherInstruction"
                                  style={{ marginBottom: '0px', marginTop: '8px' }}
                                  placeholder={
                                    props?.formType === 'email'
                                      ? 'Add in any additional instructions that will help create the email. The more information provided the  more accurate the response. Consider your audience, what specific information or message you want to convey, what objective you are trying to achieve.!'
                                      : 'Add in any additional instructions that will help create the email.Example can include:  What specific information or message do you want to convey to convey?'
                                  }
                                  size="small"
                                  isSmall
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </form>
                    );
                  }}
                </Form>
              ) : null}
              {step === 1 ? (
                <AIPromptView
                  aiResponse={aiResponse}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  classes={classes}
                  refetchContent={regenerateContent}
                  loading={loading}
                />
              ) : null}
            </Box>
            <Box sx={{ width: '100%' }}>
              <Box className={classes.cardFooter}>
                {step === 0 ? (
                  <AIButton
                    classes={classes}
                    loading={loading}
                    step={step}
                    label={'Generate email'}
                  />
                ) : null}
                {step === 1 ? (
                  <AIInsertButton
                    onClick={() => insertSelectedTemplate()}
                    classes={classes}
                    step={step}
                    disabled={!selectedOption?.toString()}
                    label={'Insert'}
                  />
                ) : null}
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default withSocketStore(AIWriteForm);
