import axios from 'axios';

const aiServicesApi = axios.create({
  baseURL: process.env.REACT_APP_AISERVICE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const registerAiUser = async (data) => {
  return aiServicesApi.post('/user/register', data);
};

export const syncUser = async (data) => {
  return aiServicesApi.post('/user/sync', data);
};
