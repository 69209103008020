import * as React from 'react';
import { FormControlLabel, List, Button, Radio, RadioGroup } from '@mui/material';
import styled from 'styled-components';

const CustomFormControlLabel = styled(FormControlLabel)`
  text-align: center;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  .MuiFormControlLabel-label {
    text-align: center;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .Mui-checked {
    color: #1976d2;
  }

  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
  }
`;

const StyledButton = styled(Button)`
  border-radius: 4px;
  display: flex;
  height: 36px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  text-transform: none;
`;

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  gap: 8px;
  //flex: 1 0 0;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 8px;
`;

const buttonList = [
  { label: 'Automated', value: 'automation' },
  { label: 'Manual', value: 'manual' },
];

export default function SequenceFormTabBar({ templateAutomationType, setTemplateAutomationType }) {
  return (
    <StyledRadioGroup aria-label="Your plan" name="people" defaultValue="Individual">
      {buttonList.map((item, index) => (
        <StyledButton
          sx={(theme) => ({
            background:
                templateAutomationType === item?.value ? theme.palette.background.darkBlue : '#F5F5F5',
          })}
          onClick={() => setTemplateAutomationType(item?.value)}
          fullWidth
        >
          <CustomFormControlLabel
            sx={(theme) => ({
              color: templateAutomationType === item?.value ? theme.palette.secondary.main : '#333',
            })}
            value={item?.value}
            control={<Radio checked={templateAutomationType === item?.value} />}
            label={item?.label}
          />
        </StyledButton>
      ))}
    </StyledRadioGroup>
  );
}
