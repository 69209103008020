import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import {
  Grid,
  Box,
  Typography,
  Tooltip,
  TableCell,
  TableBody,
  Stack,
  CardContent,
  Card,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Form, Spacer } from 'src/components/shared';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { fetchUsersLookup, fetchSingleUser } from 'src/modules/users/api/usersApi';
import { tenantPartnersLookup } from 'src/modules/reseller/api/resellerApis';

import { FieldArray } from 'formik';
import { isTenantApprovedReseller } from 'src/modules/app/actions/appSelector';
import { fetchTeamsLookup, fetchTeam } from 'src/modules/admin/api/adminApi';
import InfoIcon from '@mui/icons-material/Info';
import { Table } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { AddCircleOutline } from '@mui/icons-material';
import { SettingsPageCard, SettingsPageCardContent } from '../styles';

const TitleBar = ({ title, tooltip, smallText }) => {
  return (
    <Box display="flex" alignItems="center">
      <Typography
        variant="h4"
        sx={{
          color: '#000000DE',
          fontSize: smallText ? '16px' : '18px',
          fontWeight: smallText ? 400 : 500,
        }}
      >
        {title}
      </Typography>
      {tooltip && tooltip !== '' ? (
        <>
          <Spacer y={1} x={1} />
          <Tooltip title={tooltip}>
            <InfoIcon style={{ fill: 'rgba(0, 0, 0, 0.26)', width: 22 }} />
          </Tooltip>
        </>
      ) : null}
    </Box>
  );
};

const defaultOptions = [
  {
    attribute: {},
    fieldId: '',
    value: [],
  },
];

function SequenceSettingsForm({
  integrations,
  sequence,
  sequenceAccessTypeList,
  sequenceEmailTypeList,
  fields,
  isOnboarding,
  moveToNext,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [customFields, setCustomFields] = useState([]);

  // const [options, setOptions] = useState(sequence.tags || []);

  const integrationData = useMemo(() => {
    if (integrations && integrations.data?.length > 0) {
      let data = integrations?.data?.find((tenant) => {
        if (
          ['bullhorn', 'vincere', 'salesforce', 'hubspot', 'jobadder']?.includes(
            tenant?.name?.toLowerCase(),
          )
        )
          return tenant?.status === 'active';
      });
      return data;
    }
  }, [integrations]);

  const prospectsType = {
    ...(!['Salesforce', 'Hubspot']?.includes(integrationData?.name)
      ? { candidate: 'Candidate' }
      : {}),
    contact: 'Contact',
    ...(integrationData?.name === 'Bullhorn' ? { lead: 'Lead' } : {}),
  };

  useEffect(() => {
    setCustomFields(
      fields
        .filter(
          (field) =>
            field.type !== 'default' &&
            field?.fieldOptions?.showOnForm &&
            field?.fieldOptions?.showOnForm === true,
        )
        .sort(function (a, b) {
          return a?.fieldOptions &&
            a?.fieldOptions?.sequences &&
            b?.fieldOptions &&
            b?.fieldOptions?.sequences
            ? a.fieldOptions?.sequences.sortOrder - b.fieldOptions?.sequences.sortOrder
            : -1;
        }),
    );
  }, [fields]);

  useEffect(() => {
    if (sequence?.sharedWithUsers && sequence?.sharedWithUsers.length) {
      fetchDefUsers();
    }
    if (sequence?.sharedWithTeams && sequence?.sharedWithTeams.length) {
      fetchDefTeams();
    }
  }, [sequence]);

  const isReseller = useSelector((state) => isTenantApprovedReseller(state));
  const sequenceCategories = useSelector((state) => state.app.globals?.sequenceCategories);
  const attributes = useSelector((state) =>
    state.app?.attributes
      ? state.app.attributes.filter(
          (k) =>
            ((k.type === 'custom' &&
              k.fieldOptions?.showOnForm === true &&
              k.status === 'active') ||
              (k.fieldType === 'lookup' && k.status === 'active')) &&
            k.fieldName !== 'country',
        )
      : [],
  );

  const categories = useMemo(() => {
    if (sequenceCategories && sequenceCategories.length) {
      if (isReseller == true) {
        return [{ value: 'reseller', label: 'Reseller' }, ...sequenceCategories];
      } else {
        return sequenceCategories;
      }
    } else {
      return [];
    }
  }, [sequenceCategories, isReseller]);

  const fetchDefTeams = async () => {
    setLoading(true);
    const promises = [];
    for (let i = 0; i < sequence?.sharedWithTeams.length; i++) {
      const tid = sequence?.sharedWithTeams[i];
      promises.push(fetchTeam(tid));
    }
    let response = await Promise.all(promises);
    setLoading(false);
    if (response && response.length) {
      const teams = response.map((team) => ({
        ...team.team,
        value: team.team?.name || '',
      }));
      setTeams(teams);
    }
  };

  const fetchDefUsers = async () => {
    setLoading(true);
    const promises = [];
    for (let i = 0; i < sequence?.sharedWithUsers.length; i++) {
      const uid = sequence?.sharedWithUsers[i];
      promises.push(fetchSingleUser(uid));
    }

    let response = await Promise.all(promises);
    setLoading(false);
    if (response && response.length) {
      const sharedUsers = response.map((user) => ({
        ...user.user,
        name: `${user.user.title} ${user.user.fname} ${user.user.lname}`,
      }));
      setUsers(sharedUsers);
    }
  };

  const handleSubmit = async (values, form) => {
    const data = JSON.parse(JSON.stringify(values));

    data.sharedWithTeams = data.sharedWithTeams.map((team) => team.id);
    data.sharedWithUsers = data.sharedWithUsers.map((team) => team.id);
    data.partner = data?.partner && data?.partner?.id ? data?.partner.id : null;
    if (data?.tags?.length)
      data.tags = data.tags
        .filter((k) => k.fieldId)
        .map((k) => {
          return {
            ...k,
            fieldName: k.attribute._fieldName,
            value: Array.isArray(k.value)
              ? k.value.map((item) => (typeof item === 'string' ? item : item.value))
              : k.value,
          };
        });
    setLoading(true);
    props.putSequence(sequence.id, data);
    moveToNext();
    setLoading(false);
  };

  const customValues = useMemo(() => {
    const value = {};
    for (let i = 0; i < customFields.length; i++) {
      const field = customFields[i];
      value[field.fieldName] =
        sequence && sequence[field.fieldName] ? sequence[field.fieldName] : '';
    }
    return value;
  }, [customFields, sequence]);

  return (
    <Form
      initialValues={{
        name: sequence?.name || '',
        description: sequence?.description || '',
        crmAutoAdd: sequence?.crmAutoAdd || false,
        enrollmentEventType: sequence?.enrollmentEventType || '',
        crmContactType: sequence?.crmContactType || '',
        accessType: sequence?.accessType || '',
        audienceEmailType: sequence?.audienceEmailType || '',
        sharedWithUsers: users,
        sharedWithTeams: teams,
        testBatch: sequence?.testBatch || 200,
        winnerPercentage: sequence?.winnerPercentage || 75,
        variancePercentage: sequence?.variancePercentage || 15,
        bhContactType: sequence?.bhContactType || '',
        bhSyncRule: sequence?.bhSyncRule || '',
        trackOpen: sequence?.trackOpen || false,
        trackClick: sequence?.trackClick || false,
        allowMultiple: sequence?.allowMultiple || false,
        category: sequence?.category || false,
        partner: sequence?.partner || {},
        // test: options,
        tags: sequence?.tags || [],
        // ...customValues,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Please enter sequence name.'),
        // description: Yup.string().required('Please enter sequence description.'),
        audienceEmailType: Yup.string().required('Please select sequence type.'),
        accessType: Yup.string().required('Please select Sequence access.'),
        category: Yup.string().required('Please select the purpose of your email'),
        enrollmentEventType: Yup.string().when('crmAutoAdd', {
          is: true,
          then: Yup.string().required('Please select event type.'),
          otherwise: Yup.string(),
        }),
        crmContactType: Yup.string().when('crmAutoAdd', {
          is: true,
          then: Yup.string().required('Please select type of prospect.'),
          otherwise: Yup.string(),
        }),
        partner: Yup.object().test('reseller', 'Please choose a Partner', function (item) {
          if (this.parent.category === 'reseller') {
            return item?.id && item?.id !== '';
          }
          return true;
        }),
        // winnerPercentage: Yup.number()
        //   .typeError('Winner percentage must be a number')
        //   .required()
        //   .max(100, 'Please enter a valid percentage between 1 to 100')
        //   .positive(),
        // variancePercentage: Yup.number()
        //   .typeError('Variance percentage must be a number')
        //   .required()
        //   .max(100, 'Please enter a valid percentage between 1 to 100')
        //   .positive(),
        tags: Yup.array().of(
          Yup.object().shape({
            fieldId: Yup.string().required('This field is required.'),
            value: Yup.mixed().test('value', 'This field is required.', function (item) {
              if (!item || item?.length) return true;
              return false;
            }),
          }),
        ),

        ...(customFields &&
          customFields.length &&
          Object.assign(
            {},
            ...customFields
              .filter((item) => item.fieldOptions?.required && item.fieldOptions?.required === true)
              .map((field) => ({
                [field.fieldName]: Yup.string().required(`${field.label} is a required field!`),
              })),
          )),
      })}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ values, ...formProps }) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formProps.submitForm();
              return false;
            }}
            id="sequence-settings-form"
            style={{ height: '100%' }}
            noValidate
          >
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              justifyContent="space-between"
              height="100%"
              width="100%"
              gap="16px"
            >
              <SettingsPageCard>
                <SettingsPageCardContent>
                  <TitleBar title="Sequence overview" />
                  <Spacer x={1} y={1} />
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Form.Field.Input
                        fullWidth
                        multiline
                        variant="outlined"
                        name="name"
                        label="Sequence name"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Form.Field.Select
                        options={sequenceEmailTypeList || []}
                        fullWidth
                        variant="outlined"
                        name="audienceEmailType"
                        label="Email type"
                        optLabel="label"
                        optValue="value"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Form.Field.Select
                        options={categories}
                        fullWidth
                        variant="outlined"
                        name="category"
                        label="Purpose"
                        optLabel="label"
                        optValue="value"
                        style={{ marginTop: '0px' }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      {values?.category === 'reseller' && isReseller && (
                        <Form.Field.AutoComplete
                          multiple={false}
                          options={[]}
                          fullWidth
                          variant="outlined"
                          name="partner"
                          label="Partner"
                          style={{ marginTop: '0px' }}
                          remoteMethod={(val) => {
                            return tenantPartnersLookup('', val);
                          }}
                          optLabel="name"
                          optValue="id"
                        />
                      )}
                    </Grid>
                  </Grid>
                </SettingsPageCardContent>
              </SettingsPageCard>
              {/*<Form.Field.Input*/}
              {/*    fullWidth*/}
              {/*    multiline*/}
              {/*    variant="outlined"*/}
              {/*    name="description"*/}
              {/*    label="Sequence Description"*/}
              {/*    maxRows={4}*/}
              {/*/>*/}

              <SettingsPageCard>
                <SettingsPageCardContent>
                  <TitleBar
                    title="Permission"
                    tooltip="Set permission on who has access to the sequence."
                  />
                  <Spacer x={1} y={1} />
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Form.Field.Select
                        options={sequenceAccessTypeList || []}
                        fullWidth
                        variant="outlined"
                        name="accessType"
                        label="Sequence access"
                        optLabel="label"
                        optValue="value"
                        onChange={(val) => {
                          switch (val) {
                            case 'private':
                              formProps.setFieldValue('sharedWithUsers', []);
                              break;
                            case 'team':
                              formProps.setFieldValue('sharedWithTeams', []);
                              break;
                            case 'organisation':
                            default:
                              formProps.setFieldValue('sharedWithUsers', []);
                              formProps.setFieldValue('sharedWithTeams', []);
                              break;
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      {values.accessType === 'private' ? (
                        <Form.Field.AutoComplete
                          multiple={true}
                          options={users}
                          fullWidth
                          style={{ marginTop: '0px' }}
                          variant="outlined"
                          name="sharedWithUsers"
                          checkboxes={true}
                          label="Select users"
                          remoteMethod={(val) => {
                            return fetchUsersLookup(val);
                          }}
                          optLabel="name"
                          optValue="id"
                        />
                      ) : null}

                      {values.accessType === 'team' ? (
                        <Form.Field.AutoComplete
                          multiple={true}
                          options={teams}
                          fullWidth
                          style={{ marginTop: '0px' }}
                          variant="outlined"
                          name="sharedWithTeams"
                          checkboxes={true}
                          label="Select Teams"
                          remoteMethod={(val) => {
                            return fetchTeamsLookup(val);
                          }}
                          optLabel="value"
                          optValue="id"
                        />
                      ) : null}
                    </Grid>

                    <Grid item xs={12}>
                      <Box ml={'11px'}>
                        <Form.Field.Switch
                          style={{ marginTop: '0px' }}
                          sx={{ marginTop: '0px' }}
                          name="allowMultiple"
                          label="Multiple enrollment allowed"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </SettingsPageCardContent>
              </SettingsPageCard>

              <SettingsPageCard>
                <SettingsPageCardContent>
                  <TitleBar
                    title="Variant testing"
                    tooltip="Set the criteria for your A/B testing"
                  />
                  <Spacer x={1} y={1} />
                  <Grid container>
                    <Grid item xs={12}>
                      <Form.Field.Input
                        fullWidth
                        multiline
                        variant="outlined"
                        name="testBatch"
                        label="Email test batch"
                        tip="Recommend 200."
                      />
                    </Grid>
                    {/*<Grid item xs={6}>*/}
                    {/*  <Form.Field.Input*/}
                    {/*    fullWidth*/}
                    {/*    multiline*/}
                    {/*    variant="outlined"*/}
                    {/*    name="winnerPercentage"*/}
                    {/*    label="Winner Percentage"*/}
                    {/*    // tip="Recommend 75%"*/}
                    {/*  />*/}
                    {/*</Grid>*/}
                  </Grid>
                  {/*<Grid container spacing={2}>*/}
                  {/*  <Grid item xs={6}>*/}
                  {/*    <Form.Field.Input*/}
                  {/*      fullWidth*/}
                  {/*      multiline*/}
                  {/*      variant="outlined"*/}
                  {/*      name="variancePercentage"*/}
                  {/*      label="Variance Percentage to keep"*/}
                  {/*      // tip="Recommend 15%"*/}
                  {/*    />*/}
                  {/*  </Grid>*/}
                  {/*  <Grid item xs={6}></Grid>*/}
                  {/*</Grid>*/}
                </SettingsPageCardContent>
              </SettingsPageCard>

              <SettingsPageCard>
                <SettingsPageCardContent>
                  <TitleBar title="Tracking" />
                  <Spacer x={1} y={1} />
                  <Box display="flex" ml={1.75}>
                    <Form.Field.Switch
                      name="trackOpen"
                      label={
                        <Stack spacing={1} direction="row">
                          <Typography>Open tracking</Typography>
                          <TitleBar tooltip="When enabled, SuperReach will automatically add an invisible pixel to your emails which allows us to track open rates. Depending on the email client a recipient is using, the data may not be 100% accurate." />
                        </Stack>
                      }
                    />
                  </Box>
                  <Box display="flex" ml={1.75}>
                    <Form.Field.Switch
                      name="trackClick"
                      label={
                        <Stack spacing={1} direction="row">
                          <Typography>Click tracking</Typography>
                          <TitleBar tooltip="When enabled, SuperReach will automatically replace any links with a new URL that allows click tracking. This can effect the deliverability of your emails thus we recommend it is switched off." />
                        </Stack>
                      }
                    />
                  </Box>
                </SettingsPageCardContent>
              </SettingsPageCard>

              {integrationData?.name && (
                <SettingsPageCard>
                  <SettingsPageCardContent>
                    <TitleBar title="CRM integration" tooltip="CRM integration" />
                    <Spacer x={1} y={1} />
                    <Box ml={1.4}>
                      <Form.Field.Switch
                        name="crmAutoAdd"
                        label={
                          <TitleBar
                            smallText
                            title="Auto-add prospects to CRM"
                            tooltip="Auto-add prospects to CRM"
                          />
                        }
                      />
                    </Box>
                    {values?.crmAutoAdd && (
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={6}>
                          <Form.Field.Select
                            options={[
                              { label: 'Enrolled', value: 'created' },
                              { label: 'Replies', value: 'replied' },
                              { label: 'Marked as interested', value: 'interested' },
                            ]}
                            fullWidth
                            showNone={false}
                            variant="outlined"
                            name="enrollmentEventType"
                            required
                            label="Select event"
                            optLabel="label"
                            optValue="value"
                            placeholder="Select event"
                            defaultValue="created"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Form.Field.Select
                            options={Object.keys(prospectsType || {})?.map((key) => {
                              return { value: key, label: prospectsType[key] };
                            })}
                            fullWidth
                            showNone={false}
                            variant="outlined"
                            required
                            name="crmContactType"
                            optLabel="label"
                            optValue="value"
                            label="Type of prospect"
                            placeholder="Type of prospect"
                          />
                        </Grid>
                      </Grid>
                    )}
                  </SettingsPageCardContent>
                </SettingsPageCard>
              )}

              {/* {bullhornIntegration &&
                  bullhornIntegration?.status &&
                  bullhornIntegration?.status !== 'inactive' && (
                    <>
                      <Spacer x={3} y={3} />
                      <TitleBar
                        title="BullHorn Integration"
                        tooltip="Control how and when to synch your contacts enrolled in this sequence with Bullhorn"
                      />
                      <Spacer x={1} y={1} />

                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Form.Field.Select
                            options={bhContactTypeOptions || []}
                            fullWidth
                            variant="outlined"
                            name="bhContactType"
                            label="Sync to BullHorn as"
                            optLabel="name"
                            optValue="id"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Form.Field.Select
                            options={bhSyncRuleOptions || []}
                            fullWidth
                            variant="outlined"
                            name="bhSyncRule"
                            label="Sync to BullHorn when"
                            optLabel="name"
                            optValue="id"
                          />
                        </Grid>
                      </Grid>
                    </>
                  )} */}

              {customFields.length > 0 ? (
                <>
                  <SettingsPageCard>
                    <SettingsPageCardContent>
                      <TitleBar title="Custom fields" tooltip="Custom fields" />
                      <Grid container spacing={2}>
                        {customFields.map((field, index) => {
                          if (
                            field?.fieldOptions &&
                            field?.fieldOptions?.showOnForm &&
                            field?.fieldOptions?.showOnForm === true
                          ) {
                            return (
                              <Grid key={`sequence-custom-field-${index}`} item xs={6}>
                                <Form.Field.CustomField attribute={field} name={field.fieldName} />
                              </Grid>
                            );
                          }
                          return null;
                        })}
                      </Grid>
                    </SettingsPageCardContent>
                  </SettingsPageCard>
                </>
              ) : null}

              <SettingsPageCard>
                <SettingsPageCardContent>
                  <TitleBar
                    title="Field updates"
                    tooltip="Automatically add key information to profiles upon sequence enrolment to support your data integrity best practices with ease."
                  />
                  <Spacer x={1} y={1} />
                  <TableContainer>
                    <Table size="small" aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell width="30%">Field type</TableCell>
                          <TableCell width="2%"></TableCell>
                          <TableCell width="67%">{`Add the following {Field value/s}`}</TableCell>
                          <TableCell width="1%"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <FieldArray
                          name="tags"
                          render={(arrayHelpers) => (
                            <>
                              {values?.tags &&
                                values?.tags?.length > 0 &&
                                values?.tags?.map((field, index) => {
                                  return (
                                    <React.Fragment key={`tags-${index}`}>
                                      <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                      >
                                        <TableCell>
                                          <Form.Field.Select
                                            fullWidth
                                            options={attributes}
                                            variant="outlined"
                                            name={`tags[${index}].fieldId`}
                                            label={'Field type'}
                                            placeholder="Select field"
                                            optLabel="label"
                                            optValue="id"
                                            onChange={(val) => {
                                              const {
                                                fieldName,
                                                fieldOptions,
                                                fieldType,
                                                id,
                                                label,
                                                lookup,
                                              } =
                                                attributes.filter((item) => item.id === val)[0] ||
                                                {};

                                              formProps.setFieldValue(`tags[${index}].attribute`, {
                                                fieldOptions,
                                                fieldType,
                                                id,
                                                label,
                                                lookup,
                                                fieldName: `tags[${index}].value`,
                                                _fieldName: fieldName,
                                              });
                                            }}
                                          />
                                        </TableCell>
                                        <TableCell
                                          sx={{ paddingTop: '15px', width: '35px' }}
                                          size="small"
                                        >
                                          <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                          >
                                            <ChevronRight sx={{ color: 'gray' }} />
                                            <ChevronRight
                                              sx={{ marginLeft: '-15px', color: 'gray' }}
                                            />
                                          </Box>
                                        </TableCell>
                                        <TableCell>
                                          <Form.Field.CustomField
                                            attribute={field.attribute}
                                            values={values.tags[index]}
                                            sx={{ maxWidth: '452px' }}
                                            name={`tags[${index}].value`}
                                          />
                                        </TableCell>
                                        <TableCell sx={{ paddingTop: '15px' }}>
                                          <Button
                                            iconButton={true}
                                            onClick={() => {
                                              arrayHelpers.remove(index);
                                            }}
                                          >
                                            <CloseIcon />
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    </React.Fragment>
                                  );
                                })}
                              <TableRow>
                                <TableCell colSpan={4}>
                                  <Box
                                    display="flex"
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                  >
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        arrayHelpers.push(defaultOptions[0]);
                                      }}
                                      startIcon={<AddCircleOutline />}
                                    >
                                      Add tagging rule
                                    </Button>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            </>
                          )}
                        />
                      </TableBody>
                    </Table>
                  </TableContainer>
                </SettingsPageCardContent>
              </SettingsPageCard>

              {!isOnboarding ? (
                <Box mt={2} display="flex">
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    loading={loading}
                    disabled={loading}
                  >
                    Save
                  </Button>
                  <Spacer x={2} y={2} />
                  <Button
                    variant="outlined"
                    color="secondary"
                    loading={loading}
                    disabled={loading}
                    onClick={() => {
                      formProps.resetForm();
                    }}
                  >
                    Reset
                  </Button>
                </Box>
              ) : null}
            </Box>
          </form>
        );
      }}
    </Form>
  );
}
export default SequenceSettingsForm;
