import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Stepper, Step, StepLabel, StepButton, Typography, Button } from '@mui/material';
import Check from '@mui/icons-material/Check';

import { ConnectorStyles, IconStyles, StepperStyles } from './styles';

function IconComponent(props) {
  const classes = IconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle}>{props.icon}</div>
      )}
    </div>
  );
}

IconComponent.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const StepperComponent = ({ steps, activeStep, onChange, StepIconComponent, seqNav, ...props }) => {
  const classes = StepperStyles({ seqNav });

  return (
    <Stepper
      className={classes.root}
      nonLinear
      activeStep={activeStep}
      connector={<ConnectorStyles seqNav={seqNav} />}
      color={seqNav ? 'primary' : 'secondary'}
      {...props}
    >
      {steps.map((label, index) => (
        <Step
          key={`seq-onboarding-${index}`}
          completed={index < activeStep}
          color={seqNav ? 'primary' : 'secondary'}
        >
          <StepButton onClick={() => onChange?.(index)}>
            <StepLabel {...(!!StepIconComponent ? { StepIconComponent } : {})}>
              <Typography
                color={
                  seqNav
                    ? index <= activeStep
                      ? '#000000DE'
                      : '#0000001F'
                    : activeStep === index
                    ? 'textPrimary'
                    : 'textSecondary'
                }
                sx={{ textWrap: seqNav ? 'nowrap' : '' }}
                variant="body2"
              >
                {label}
              </Typography>
            </StepLabel>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
};

StepperComponent.propTypes = {
  steps: PropTypes.array,
  activeStep: PropTypes.number,
};

StepperComponent.defaultTypes = {
  steps: [],
  activeStep: 0,
};

export default StepperComponent;
