import React, { useRef } from "react";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import {Box, Typography, InputAdornment, Divider, useTheme} from "@mui/material";
import { Form, Button, Spacer } from "src/components/shared";
import { cacheTest, checkUniqueUserEmail } from "src/utils/validation";

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { ThirdPartyLoginButton } from "../styles";
import images from "src/config/images";
import { loginWithGoogle, loginWithMicrosoft } from "../../api/authApis";
import toast from "src/utils/toast";

const RegistrationForm = ({ onSubmit, loading, registerReVerification, history }) => {
  const emailUniqueTest = useRef(cacheTest(checkUniqueUserEmail));
  const theme = useTheme();

  const handleGoogleLogin = async () => {
    try {
      const params = new URLSearchParams(location.search);
      const referral = params.get("referral");
      const res = await loginWithGoogle(referral ? `?referral=${referral}&checkAuth=signup` : "?checkAuth=signup");
      window.location.replace(res);
    } catch (e) {
      toast.error("Sign up with google is Failed please try again");
    }
  };

  const handleMicroSoftLogin = async () => {
    try {
      const params = new URLSearchParams(location.search);
      const referral = params.get("referral");
      const res = await loginWithMicrosoft(referral ? `?referral=${referral}&checkAuth=signup` : "?checkAuth=signup");
      window.location.replace(res);
    } catch (e) {
      toast.error("Sign up with microsoft is Failed please try again");
    }
  };

  const handleNotVerifiedUser = async (email, userId) => {
    registerReVerification(userId);
    history.push(`/auth/verification?email=${email}&id=${userId}`);
  };

  return (
    <Form
      initialValues={{
        email: "",
        termsAccepted: false,
      }}
      validationSchema={Yup.object().shape({
        termsAccepted: Yup.boolean().oneOf([true], "Please accept terms and conditions."),
        email: Yup.string()
          .required("Email is required.")
          .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]{2,}\.[a-zA-Z]{2,}$/, "Please enter a valid email")
          .test("username-backend-validation", "This email is already registered.", async (value, { createError }) => {
            return emailUniqueTest.current(value, {
              createError,
              skipAllowed: false,
              handleNotVerifiedUser,
            });
          }),
      })}
      validateOnChange={true}
      onSubmit={onSubmit}
    >
      {(props) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            noValidate
          >
            <Form.Field.Input
              page="auth"
              fullWidth
              variant="outlined"
              errorWithIcon
              hideAsterisk
              name="email"
              placeholder="Work email"
              disabled={loading}
              autoComplete="on"
              onChange={(e) => {
                props.setFieldTouched("termsAccepted", true, false);
                props.handleChange(e);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailOutlinedIcon sx={(theme) => ({ fill: theme.palette.text.gray6 })} />
                  </InputAdornment>
                ),
              }}
            />
            <Spacer x={2} y={2} />
            <Form.Field.Checkbox
              name="termsAccepted"
              errorWithIcon
              label={
                <Typography variant="body2" component="span">
                  Get emails about product updates, industry news, and events. If you change your mind, you can
                  unsubscribe at any time. I accept{" "}
                  <a href="http://superreach.com/terms-of-service.html" target="_blank">
                    <Typography variant="body2" color="secondary" component="span">
                      Privacy Policy and Terms
                    </Typography>
                    .
                  </a>
                </Typography>
              }
            />
            <Spacer x={1} y={1} />

            <Box mt={4}>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                loading={loading}
                fullWidth
                size="large"
                blackButton
                disabled={!props?.isValid || !props?.dirty}
                endIcon={<img src={images.icons.nextIcon} alt="Next Icon" />}
              >
                Next
              </Button>
            </Box>

            <Divider sx={{ mt: 4 }}>
              <Typography
                sx={(theme) => ({
                  color: theme.palette.text.grey,
                  fontSize: "14px",
                })}
              >
                OR
              </Typography>
            </Divider>

            <Box mt={4} mb={4} justifyContent="space-between" display="flex" flexDirection="row">
              <ThirdPartyLoginButton
                sx={{ padding: "6px 13px !important" }}
                startIcon={<img src={images.icons.GoogleIcon} alt="Google" />}
                onClick={() => handleGoogleLogin()}
              >
                Sign up with Google
              </ThirdPartyLoginButton>
              <ThirdPartyLoginButton
                sx={{ padding: "6px 13px !important" }}
                onClick={() => handleMicroSoftLogin()}
                startIcon={<img src={images.icons.microSoftIcon} alt="Microsoft" />}
              >
                Sign up with Microsoft
              </ThirdPartyLoginButton>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
              <Box mt={1} display="flex" justifyContent="center">
                <Typography color="textSecondary" variant="body2">
                  Already have an account?{" "}
                  <Link
                      to="/auth/login"
                      style={{ textDecoration: 'underline', textDecorationColor: theme.palette.secondary.main,}}
                  >
                    <Typography color="secondary" variant="body2" display="inline" component="span">
                     Sign in
                    </Typography>
                  </Link>
                </Typography>
              </Box>
            </Box>
          </form>
        );
      }}
    </Form>
  );
};

export default RegistrationForm;
