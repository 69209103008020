import React, { useState, useMemo, useEffect } from 'react';

import * as Yup from 'yup';
import {
  Grid,
  Box,
  Typography,
  CircularProgress,
  Backdrop,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { Button, Form } from 'src/components/shared';
import { tenantPartnersLookup } from 'src/modules/reseller/api/resellerApis';
import {
  isTenantApprovedPartner,
  isTenantApprovedReseller,
} from 'src/modules/app/actions/appSelector';
import { useSelector } from 'react-redux';
import SeqTypeTab from './SeqTypeTab';
import { useStyles, LightTooltip } from './styles';
import { fetchBrandVoice } from '../../../api/sequenceApi';
import { capitalizeFirstLetter } from '../../../../../utils/helper';
import images from '../../../../../config/images';
import { useSocketStore, withSocketStore } from '../../../../../components/hooks/socketConnector';

const stepsList = [
  {
    label: 'LinkedIn connections',
    value: 'linkedinConnection',
  },
  {
    label: 'LinkedIn message',
    value: 'linkedinMessage',
  },
  {
    label: 'LinkedIn InMail',
    value: 'linkedinMail',
  },
  {
    label: 'LinkedIn view profile',
    value: 'linkedinViewProfile',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Call back',
    value: 'call',
  },
  {
    label: 'SMS',
    value: 'sms',
  },
  {
    label: 'Tasks',
    value: 'general',
  },
];

function AIWriteForm({
  sequenceAccessTypeList,
  sequenceEmailTypeList,
  user,
  isAIWriter,
  sequence,
  ...props
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [listOfVoice, setListOfVoice] = useState([]);

  const isReseller = useSelector((state) => isTenantApprovedReseller(state));
  const isPartner = useSelector((state) => isTenantApprovedPartner(state));
  const sequenceCategories = useSelector((state) => state.app.globals?.sequenceCategories);
  const { emit, aiResponse, socket } = useSocketStore();

  useEffect(() => {
    if (isAIWriter) {
      (async () => {
        const res = await fetchBrandVoice();
        setListOfVoice(res);
      })();
    }
  }, []);

  const categories = useMemo(() => {
    if (sequenceCategories && sequenceCategories.length) {
      if (isReseller == true && !isPartner) {
        return [{ value: 'reseller', label: 'Reseller' }, ...sequenceCategories];
      } else {
        return sequenceCategories;
      }
    } else {
      return [];
    }
  }, [sequenceCategories, isReseller]);

  const handleSubmit = async (values, form) => {
    setLoading(true);
    const data = JSON.parse(JSON.stringify(values));
    if (data?.partner) {
      if (Object.keys(data?.partner).length === 0 || data?.category !== 'reseller')
        delete data.partner; // comes as empty {}
    }

    data.partner = data?.partner && data?.partner?.id ? data?.partner.id : null;
    data.sharedWithTeams = data.sharedWithTeams.map((team) => team.id);
    data.sharedWithUsers = [user?.id];
    data.testBatch = 200;
    data.variancePercentage = 15;
    data.winnerPercentage = 75;
    data.allowRestart = false;
    data.trackClick = false;

    if (isAIWriter) {
      if (data?.toneOfVoice?.id) data.toneOfVoice = data?.toneOfVoice?.id;
      emit(
        'pipeline:execute',
        JSON.stringify({
          slug: 'create-sequence',
          payload: { text: JSON.stringify({ ...data }) },
        }),
      );
      socket.on('pipeline:execute:success', async (res) => {
        setLoading(true);
        if (sequence?.id) {
          const payload = { ...res?.json };
          if (sequence.events && sequence?.events?.length)
            payload.prevEvents = [...sequence.events];
          await props.onSubmit({ ...payload, isAIWriter }, sequence.id);
        } else {
          await props.onSubmit({ ...res?.json, isAIWriter });
        }
        setLoading(false);
      });
      socket.on('pipeline:execute:error', () => {
        setLoading(false);
      });
    } else {
      delete data.toneOfVoice;
      delete data.stepsToGenerate;
      delete data.furtherInstruction;
      if (sequence) {
        await props.onSubmit(data, sequence.id);
      } else {
        await props.onSubmit(data);
      }
      setLoading(false);
    }
  };

  const handleCheckboxChange = (e, formProps) => {
    const { value, checked } = e.target;
    const currentSteps = formProps?.values?.stepsToGenerate || [];

    if (checked) {
      formProps.setFieldValue('stepsToGenerate', [...currentSteps, value]);
    } else {
      formProps.setFieldValue(
        'stepsToGenerate',
        currentSteps.filter((step) => step !== value),
      );
    }
  };

  return (
    <>
      {props?.loading || loading ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={props?.loading || loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
      <Box className={classes.card}>
        <Typography
          fontWeight={500}
          fontSize={'18px'}
          color={'#000000DE'}
          sx={{ margin: '16px 24px' }}
        >
          {isAIWriter ? 'Sequence AI Generator' : 'Create a new sequence'}
        </Typography>
        <Form
          initialValues={{
            name: sequence?.name || '',
            description: sequence?.description || '',
            accessType: sequence?.accessType || 'private',
            audienceEmailType: sequence?.audienceEmailType || 'work',
            sharedWithUsers: [{ value: user?.id, name: user?.fname }],
            sharedWithTeams: teams,
            status: 'unpublished',
            category: sequence?.category,
            toneOfVoice: sequence?.toneOfVoice || listOfVoice || '',
            partner: {},
            stepsToGenerate: sequence?.stepsToGenerate || [],
            furtherInstruction: sequence?.furtherInstruction || '',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().when('isAIWriter', {
              is: false,
              then: Yup.string().required('Please enter sequence name.'),
            }),
            // name: Yup.string().required('Please enter sequence name.'),
            // description: Yup.string().required('Please enter sequence description.'),
            audienceEmailType: Yup.string().required('Please select sequence type.'),
            accessType: Yup.string().required('Please select Sequence access.'),
            category: Yup.string().required('Please select the purpose of your email.'),
            partner: Yup.object().test('reseller', 'Please choose a Partner', function (item) {
              if (this.parent.category === 'reseller') {
                return item?.id && item?.id !== '';
              }
              return true;
            }),
            sharedWithUsers: Yup.array().test({
              message: () => 'Please choose at least one user.',
              test: (value, values) => {
                const accessType = values.parent.accessType;
                if (accessType === 'private') {
                  return value.length > 0;
                }
                return true;
              },
            }),
            sharedWithTeams: Yup.array().test({
              message: () => 'Please choose at least one team.',
              test: (value, values) => {
                const accessType = values.parent.accessType;
                if (accessType === 'team') {
                  return value.length > 0;
                }
                return true;
              },
            }),
          })}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({ values, ...formProps }) => {
            return (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formProps.submitForm();
                  return false;
                }}
                id="create-sequence-form"
                style={{ minHeight: '365px', height: '100%', width: '100%' }}
                noValidate
              >
                <Box
                  display="flex"
                  flex={1}
                  flexDirection="column"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  height="100%"
                >
                  <Box width="100%">
                    <Grid container sx={{ padding: '0px 24px' }}>
                      {!isAIWriter && (
                        <>
                          <Grid item xs={2} container alignItems="center">
                            <Typography className={classes.label}>Name</Typography>
                          </Grid>
                          <Grid item xs={10}>
                            <Form.Field.Input
                              fullWidth
                              variant="outlined"
                              name="name"
                              label="Sequence name"
                              style={{ marginBottom: '0px' }}
                              size="small"
                            />
                          </Grid>
                        </>
                      )}

                      <Grid item xs={2} container alignItems="center">
                        <Typography className={classes.label}>Sequence type</Typography>
                      </Grid>
                      <Grid item xs={10} sx={{ marginTop: '16px' }}>
                        <SeqTypeTab
                          value={values?.audienceEmailType}
                          onClick={(value) => formProps?.setFieldValue('audienceEmailType', value)}
                          formProps={formProps}
                        />
                      </Grid>

                      {/*<Grid item xs={6}>*/}
                      {/*  <Form.Field.Select*/}
                      {/*    options={sequenceEmailTypeList || []}*/}
                      {/*    fullWidth*/}
                      {/*    variant="outlined"*/}
                      {/*    name="audienceEmailType"*/}
                      {/*    label="Email type"*/}
                      {/*    optLabel="label"*/}
                      {/*    optValue="value"*/}
                      {/*  />*/}
                      {/*</Grid>*/}
                      <Grid item xs={2} container alignItems="center">
                        <Typography className={classes.label}>Purpose</Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Form.Field.Select
                          options={categories}
                          placeholder={'Please select'}
                          fullWidth
                          size="small"
                          variant="outlined"
                          name="category"
                          // label="Category"
                          optLabel="label"
                          optValue="value"
                        />
                      </Grid>

                      {values?.category === 'reseller' && isReseller && (
                        <>
                          <Grid item xs={2} />
                          <Grid item xs={10}>
                            <Form.Field.AutoComplete
                              multiple={false}
                              options={[]}
                              fullWidth
                              size="small"
                              variant="outlined"
                              name="partner"
                              label="Partner"
                              remoteMethod={(val) => {
                                return tenantPartnersLookup('', val);
                              }}
                              optLabel="name"
                              optValue="id"
                            />
                          </Grid>
                        </>
                      )}
                      {isAIWriter && (
                        <>
                          <Grid item xs={2} container alignItems="center">
                            <Typography className={classes.label}>Tone of Voice</Typography>
                          </Grid>
                          <Grid item xs={10}>
                            <Form.Field.AutoComplete
                              // options={aiTonality}
                              options={[]}
                              fullWidth
                              variant="outlined"
                              name="toneOfVoice"
                              placeholder={'Please select'}
                              // label="Please select"
                              optValue="id"
                              optLabel="name"
                              // isSmall
                              remoteMethod={(val) => fetchBrandVoice(val)}
                              size={'small'}
                              InputLabelProps={{
                                sx: {
                                  fontSize: '14px',
                                },
                              }}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            container
                            alignItems="center"
                            sx={{ marginTop: '8px' }}
                          >
                            <Typography className={classes.label}>
                              Select steps to include:
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sx={{ marginTop: '3px' }}>
                            <Grid container>
                              {stepsList.map(({ label, value }, index) => (
                                <Grid item xs={3}>
                                  <FormControlLabel
                                    key={index}
                                    control={
                                      <Checkbox
                                        checked={values?.stepsToGenerate?.includes(value)}
                                        onChange={(e) =>
                                          handleCheckboxChange(e, { values, ...formProps })
                                        }
                                        name={label}
                                        value={value}
                                      />
                                    }
                                    label={
                                      <Typography fontSize="14px" color={'#666'}>
                                        {capitalizeFirstLetter(label)}
                                      </Typography>
                                    }
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            container
                            alignItems="center"
                            sx={{ marginTop: '16px' }}
                          >
                            <Typography className={classes.label}>Further instructions</Typography>
                            <LightTooltip
                              arrow
                              title={
                                <>
                                  <Box display={'flex'} flexDirection={'row'} gap={'10px'}>
                                    <img src={images.icons.largeInfoIcon} alt={''} />
                                    <Typography
                                      className={classes.tootltipHeader}
                                      component={'span'}
                                    >
                                      Further instructions
                                    </Typography>
                                  </Box>
                                  <Typography className={classes.tootltipContent} component={'div'}>
                                    Provide any further details that will help create the best
                                    sequence for your needs.
                                  </Typography>
                                </>
                              }
                              placement={'top'}
                            >
                              <img
                                src={images.icons.infoIcon}
                                alt={''}
                                style={{ marginLeft: '9px' }}
                              />
                            </LightTooltip>
                          </Grid>
                          <Grid item xs={12} container alignItems="center">
                            <Form.Field.Input
                              fullWidth
                              multiline
                              // rows={4}
                              minRows={5}
                              variant="outlined"
                              name="furtherInstruction"
                              placeholder={`Add in any additional instructions that will help create the email. Example can include:  What specific information or message do you want to convey to convey?`}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            container
                            justifyContent="flex-end"
                            sx={{ marginTop: '24px', paddingBottom: '24px' }}
                          >
                            <Button
                              form={'create-sequence-form'}
                              variant="contained"
                              color="secondary"
                              type={'submit'}
                            >
                              Next
                            </Button>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Box>
                </Box>
              </form>
            );
          }}
        </Form>
      </Box>
    </>
  );
}

export default withSocketStore(AIWriteForm);
