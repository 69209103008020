import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { Button, Form, Modal, Spacer } from 'src/components/shared';
import { isEmpty } from 'lodash';
import { Stack } from '@mui/material';
import { formatCurrency } from '../../../../utils/helper';

function CreditRecharge({
  open,
  creditRechargeType,
  subscriptions,
  onClose,
  initiatePayment,
  loading,
  tenant,
}) {
  const [costPerUnit, setCostPerUnit] = useState('');
  const plans = useSelector((state) => state.app.globals?.charges ?? []);
  const data = subscriptions?.creditsPurchased?.[creditRechargeType] ?? {};

  const dataPlans = useMemo(() => {
    if (plans && !isEmpty(plans)) {
      return plans.data.plans.map((item) => {
        return {
          ...item,
          name: `${item.credits} credits for $${item.price}`,
        };
      });
    }
  }, [plans]);

  const roleplayPlans = useMemo(() => {
    if (plans && !isEmpty(plans)) {
      return plans.roleplay.plans.map((item) => {
        return {
          ...item,
          name: `${item.credits} roleplay minutes for $${item.price}`,
        };
      });
    }
  }, [plans]);

  const handleSubmit = (values) => {
    initiatePayment(values.plan, undefined, true);
  };

  const onChangePlan = (selectedPlanId) => {
    const currentPlans = plans?.[creditRechargeType]?.plans ?? [];
    const selectedPlan = currentPlans?.find(({ planId }) => planId === selectedPlanId) ?? {};
    setCostPerUnit(selectedPlan?.costPerUnit ?? '');
  };

  return (
    <Modal
      open={open}
      sx={{
        '& .MuiDialogTitle-root': {
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
      }}
      title={
        <Stack gap="6px">
          <Typography fontSize="18px" fontWeight="500">
            {creditRechargeType === 'roleplay' ? 'Buy Role-play minutes' : 'Buy credits'}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {creditRechargeType === 'roleplay'
              ? 'Add role-play minutes to your company account to keep using Ai role-plays. '
              : 'Add data credits to your company account to enrich prospect profiles.'}
          </Typography>
        </Stack>
      }
      onClose={() => {
        onClose();
        setCostPerUnit('');
      }}
    >
      <Spacer x={2} y={2} />
      <Spacer x={2} y={2} />
      <Stack direction="flex" justifyContent="space-between">
        <Typography variant="body1" fontWeight="500" color="textPrimary">
          Current {creditRechargeType === 'data' ? 'credit' : 'minute'} balance
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {costPerUnit}
        </Typography>
      </Stack>
      <Spacer x={0.5} y={0.5} />
      <Typography variant="body1" color="textSecondary">
        {creditRechargeType === 'data'
          ? formatCurrency(tenant?.credits)
          : tenant?.roleplayMinutes > 0
              ? Math.round(tenant?.roleplayMinutes)
              : 0}{' '}
        {creditRechargeType === 'data' ? 'credits' : 'minutes'} remaining
      </Typography>
      <Spacer x={1} y={1} />
      <Form
        initialValues={{
          plan: '',
        }}
        validationSchema={Yup.object().shape({
          plan: Yup.string().required('Please choose a plan!'),
        })}
        onSubmit={handleSubmit}
      >
        {(formProps) => {
          return (
            <>
              {creditRechargeType === 'roleplay' && (
                <Form.Field.Select
                  options={roleplayPlans}
                  onChange={onChangePlan}
                  optValue="planId"
                  optLabel="name"
                  variant="outlined"
                  label="Add minutes"
                  placeholder="Choose minute batch..."
                  name="plan"
                  fullWidth
                />
              )}

              {creditRechargeType === 'data' && (
                <Form.Field.Select
                  options={dataPlans}
                  onChange={onChangePlan}
                  optValue="planId"
                  optLabel="name"
                  variant="outlined"
                  label="Add credits"
                  placeholder="Choose credit batch..."
                  name="plan"
                  fullWidth
                />
              )}

              <Spacer x={1} y={1} />
              <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={formProps.submitForm}
                  loading={loading}
                >
                  Confirm
                </Button>
              </Box>
            </>
          );
        }}
      </Form>
    </Modal>
  );
}

export default CreditRecharge;
