import React, { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import UsersGrid from '../../components/Users/UsersGrid';
import { Button, Drawer } from 'src/components/shared';
import UserForm from '../../components/Users/UserForm';
import ManageUserLicense from '../../components/ManageUserLicense';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const getSubscriptionTitle = (key) =>
  key === 'lms' ? 'Learning' : key === 'outreach' ? 'Outreach' : '';

export const planSortOrder = ['outreach', 'lms'];
export const subscriptionTypeOrder = ['monthly', 'yearly'];
export const subscriptionPeriod = {
  outreachMonthly: 'outreachMonthly',
  outreachYearly: 'outreachAnnual',
  lmsMonthly: 'lmsmonthly',
  lmsYearly: 'lmsannual',
};

function UsersView({ usersTabs, users, total, userBulkUpdate, ...props }) {
  const history = useHistory();
  const tenant = useSelector((state) => state.app.tenant);
  const user = useSelector((state) => state.auth.user);
  const globalConfig = useSelector((state) => state.app.globals);
  const [drawerOpen, setDrawerOpen] = useState({ edit: null, open: false });

  const plansData = [
    'lmsAnnual',
    'lmsAnnual_consumed',
    'lmsMonthly',
    'lmsMonthly_consumed',
    'outreachAnnual',
    'outreachAnnual_consumed',
    'outreachMonthly',
    'outreachMonthly_consumed',
  ]?.reduce((acc, key) => {
    const planKey = [
      'lmsAnnual',
      'lmsAnnual_consumed',
      'lmsMonthly',
      'lmsMonthly_consumed',
    ]?.includes(key)
      ? 'lms'
      : [
          'outreachAnnual',
          'outreachAnnual_consumed',
          'outreachMonthly',
          'outreachMonthly_consumed',
        ]?.includes(key)
      ? 'outreach'
      : '';
    const isConsumed = !!key?.split?.('_')?.[1];
    const used = isConsumed ? tenant[key] : 0;
    const quantity = !isConsumed ? tenant[key] : 0;
    const period = [
      'outreachAnnual',
      'outreachAnnual_consumed',
      'lmsAnnual',
      'lmsAnnual_consumed',
    ]?.includes(key)
      ? 'yearly'
      : [
          'lmsMonthly',
          'lmsMonthly_consumed',
          'outreachMonthly',
          'outreachMonthly_consumed',
        ]?.includes(key)
      ? 'monthly'
      : '';
    if (planKey && (quantity || used))
      acc[planKey] = {
        title: getSubscriptionTitle(planKey),
        plans: {
          ...(acc?.[planKey]?.plans ?? {}),
          [period]: {
            quantity: (acc?.[planKey]?.plans?.[period]?.quantity ?? 0) + quantity,
            used: (acc?.[planKey]?.plans?.[period]?.used ?? 0) + used,
          },
        },
        ...(planKey === 'lms' ? { categories: globalConfig?.lmsCategories } : {}),
      };
    return acc;
  }, {});

  return (
    <Stack gap={0.5}>
      {!user?.isAdmin && (<Stack direction="row" justifyContent={'space-between'} alignItems={'flex-start'}>
        <Stack gap={1.4}>
          <Typography>Licences remaining</Typography>
          <Stack direction="row" gap={2}>
            {Object.keys(plansData || {})
              ?.sort((a, b) => planSortOrder.indexOf(a) - planSortOrder.indexOf(b))
              ?.map((subscriptionType) => {
                const subscriptionPlan = plansData[subscriptionType];
                let available = 0;
                let total = 0;
                let used = 0;
                Object.values(subscriptionPlan?.plans)?.forEach((plan) => {
                  total = total + (plan.quantity || 0);
                  used = used + (plan.used || 0);
                });
                available += total - used;
                return (
                  <Stack
                    padding="12px 60px"
                    border={'1px solid #00000042'}
                    borderRadius={'4px'}
                    direction={'row'}
                    alignItems={'center'}
                    gap={'7px'}
                  >
                    <Typography>{subscriptionPlan?.title}</Typography>
                    <span>|</span>
                    <Typography fontWeight={500}>
                      {available} of {total}
                    </Typography>
                  </Stack>
                );
              })}
          </Stack>
        </Stack>
        <Button color="secondary" onClick={() => history.push('/admin/billing/subscriptions')}>
          Add more licences &nbsp; <OpenInNewIcon />
        </Button>
      </Stack>)}

      <UsersGrid
        drawerToggle={setDrawerOpen}
        users={users}
        plansData={plansData}
        userBulkUpdate={userBulkUpdate}
        userBulkDelete={props.userBulkDelete}
        {...props}
      />

      <Drawer
        title={
          <Typography fontSize={'18px'} fontWeight={600}>
            {drawerOpen.edit ? 'Edit User' : 'Add User'}
          </Typography>
        }
        open={drawerOpen.open}
        sx={{}}
        onClose={() => {
          setDrawerOpen({ edit: null, open: false });
        }}
      >
        {drawerOpen.open && (
          <UserForm
            plansData={plansData}
            loading={props.loading}
            editUser={drawerOpen.edit}
            openSubscriptionPage={drawerOpen.subscription ? 1 : 0}
            total={total}
            drawerToggle={setDrawerOpen}
            {...props}
          />
        )}
      </Drawer>
      <Drawer title={'Manage Users'} open={false} onClose={() => {}}>
        <ManageUserLicense />
      </Drawer>
    </Stack>
  );
}

export default UsersView;
