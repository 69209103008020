import React, { useEffect, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { PageTitle } from 'src/components/App';
import OnboardingView from './OnboardingView';

import { fetchOnboardingStatus, putOnboardingStatus } from '../../actions/appActions';
import { getContactFields } from 'src/modules/app/actions/appSelector';
import {
  toggleAddContactForm,
  toggleAddTaskForm,
  toggleAddSequenceForm,
} from 'src/modules/app/actions/appActions';
import { showWelcomeModal } from 'src/modules/auth/actions/authActions';
import OutReachOnboardingView from './OutReachOnBoardingView';
import { fetchOnboardingData } from "../../api/appApis";

export const OnboardingContainer = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    props.fetchOnboardingStatus();
  }, []);
  useEffect(() => {
    const query = new URLSearchParams(location?.search);
    const welcomeModalOpen = query.get('welcome');
    const isNewUser = query.get('newUser');

    if (
      welcomeModalOpen &&
      (props?.user?.lmsmonthly || props?.user?.lmsannual) &&
      (props?.user?.outreachAnnual || props?.user?.outreachMonthly)
    )
      dispatch(showWelcomeModal(welcomeModalOpen, isNewUser));
  }, [location.search]);

  const hasLmsAccess = useMemo(() => {
    return props?.user?.lmsmonthly === true || props?.user?.lmsannual === true;
  }, [props?.user]);

  const hasOutreachAccess = useMemo(() => {
    if (!props?.user?.outreachAnnual && !props?.user?.outreachMonthly && !hasLmsAccess) return true;
    return props?.user?.outreachAnnual === true || props?.user?.outreachMonthly === true;
  }, [props?.user]);

  return (
    <>
      <PageTitle title="Outreach Video Library" />
      {hasOutreachAccess ? (
        <>
          <OutReachOnboardingView {...props} />
        </>
      ) : (
        <OnboardingView {...props} />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  onboarding: state.app.onboarding,
  showContactForm: state.app.showContactForm,
  fields: getContactFields(state),
});

const mapDispatchToProps = (dispatch) => ({
  toggleAddContactForm: () => dispatch(toggleAddContactForm()),
  toggleAddTaskForm: () => dispatch(toggleAddTaskForm()),
  toggleAddSequenceForm: () => dispatch(toggleAddSequenceForm()),

  fetchOnboardingStatus: () => dispatch(fetchOnboardingStatus()),
  putOnboardingStatus: (key, value) => {
    return new Promise((resolve, reject) => {
      dispatch(putOnboardingStatus(key, value, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingContainer);
