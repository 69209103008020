import React, { useEffect, useState, useMemo, useRef, Fragment } from 'react';
import { connect, useSelector } from 'react-redux';
import * as Yup from 'yup';
import moment from 'moment';
import { Grid, Box, Typography, Tooltip, Stack } from '@mui/material';
import { Button, Form, Spacer } from 'src/components/shared';
import { postTask, putTask } from 'src/modules/tasks/actions/taskActions';
import { toggleAddTaskForm } from 'src/modules/app/actions/appActions';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';
import { fetchContactsLookup } from 'src/modules/contacts/api/contactApis';
import { getTaskFields } from 'src/modules/app/actions/appSelector';
import { fetchTemplatesLookup } from 'src/modules/app/api/appApis';
import { getInputReplacementVariables } from 'src/modules/app/actions/appSelector';
import { fetchTemplateDetails } from 'src/modules/admin/api/adminApi';
import { getPriorityIcon, getTaskTypeIcon } from '../../utils/tasksUtils';
import InfoIcon from '@mui/icons-material/Info';
import SequenceFormTabBar from 'src/modules/sequence/components/SequenceEvents/SequenceFormTabBar';

const TitleBar = ({ title, tooltip }) => {
  return (
    <Box display="flex" alignItems="center">
      <Typography variant="h4">{title}</Typography>
      {tooltip && tooltip !== '' ? (
        <Fragment>
          <Spacer y={1} x={1} />
          <Tooltip title={tooltip}>
            <InfoIcon style={{ fill: 'rgba(0, 0, 0, 0.26)', width: 22 }} />
          </Tooltip>
        </Fragment>
      ) : null}
    </Box>
  );
};

function TaskForm({
  taskFields,
  formData,
  taskPriorityList,
  taskTypeList,
  editTask,
  toggleAddTaskForm,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [contact, setContact] = useState();
  const [editUserTask, setEditUserTask] = useState({});
  const [customFields, setCustomFields] = useState([]);
  const [templateAutomationType, setTemplateAutomationType] = useState(editTask.mode || 'manual');

  const tenant = useSelector((state) => state.app.tenant);
  const replacementVariables = useSelector((state) => getInputReplacementVariables(state));

  useEffect(() => {
    setCustomFields(
      taskFields
        .filter(
          (field) =>
            field.type !== 'default' &&
            field?.fieldOptions?.showOnForm &&
            field?.fieldOptions?.showOnForm === true,
        )
        .sort(function (a, b) {
          return a?.fieldOptions &&
            a?.fieldOptions?.tasks &&
            b?.fieldOptions &&
            b?.fieldOptions?.tasks
            ? a.fieldOptions?.tasks.sortOrder - b.fieldOptions?.tasks.sortOrder
            : -1;
        }),
    );
  }, [taskFields]);

  useEffect(() => {
    if (editTask && editTask?.id && editTask?.id !== '') {
      if (editTask?.contact && editTask?.contact.id) {
        setContact({
          ...editTask?.contact,
          name: `${
            editTask?.contact.title && editTask?.contact.title !== ''
              ? editTask?.contact.title + ' '
              : ''
          } ${editTask?.contact.fname} ${editTask?.contact.lname}`,
        });
      }

      if (editTask?.assignedTo && editTask?.assignedTo.id) {
        setUser({
          ...editTask?.assignedTo,
          name: `${
            editTask?.assignedTo.title && editTask?.assignedTo.title !== ''
              ? editTask?.assignedTo.title + ' '
              : ''
          } ${editTask?.assignedTo.fname} ${editTask?.assignedTo.lname}`,
        });
      }
    } else {
      setUser({
        ...user,
        name: `${props.user?.title && props.user.title !== '' ? props.user?.title + ' ' : ''} ${
          props.user?.fname
        } ${props.user?.lname}`,
      });
    }
    if (editTask?.template)
      fetchTemplateDetails(editTask?.template?.id || editTask?.template).then((res) =>
        setEditUserTask(res?.template),
      );
  }, [editTask]);

  const priorities = useMemo(() => {
    return taskPriorityList
      ? taskPriorityList.map((item) => {
          return {
            ...item,
            icon: getPriorityIcon(item.id),
          };
        })
      : [];
  }, [taskPriorityList]);

  const taskTypes = [
    {
      id: 'linkedinConnection',
      name: 'LinkedIn Connection',
      icon: getTaskTypeIcon('linkedin'),
    },
    {
      id: 'linkedinMessage',
      name: 'LinkedIn Message',
      icon: getTaskTypeIcon('linkedin'),
    },
    {
      id: 'linkedinMail',
      name: 'LinkedIn Inmail',
      icon: getTaskTypeIcon('linkedin'),
    },
    {
      id: 'linkedinViewProfile',
      name: 'LinkedIn View Profile',
      icon: getTaskTypeIcon('linkedin'),
    },
    {
      id: 'email',
      name: 'Automated Email',
      icon: getTaskTypeIcon('email'),
    },
    {
      id: 'selectEmailTemplate',
      name: 'Select Email Template',
      icon: getTaskTypeIcon('selectEmailTemplate'),
    },
    {
      id: 'call',
      name: 'Scheduled Call',
      icon: getTaskTypeIcon('call'),
    },
    {
      id: 'general',
      name: 'To Do',
      icon: getTaskTypeIcon('general'),
    },
    {
      id: 'sms',
      name: 'SMS',
      icon: getTaskTypeIcon('sms'),
    },
  ];

  const searchCandidates = async (search, lookupType) => {
    let res = [];
    try {
      if (lookupType === 'contact') {
        res = await fetchContactsLookup(search);
      } else if (lookupType === 'assignedTo') {
        res = await fetchUsersLookup(search);
      }
      return res;
    } catch (error) {
      return res;
    }
  };

  const handleSubmit = async (values, form) => {
    const data = { ...values };
    data.assignedTo = data.assignedTo.id || props.user?.id;
    data.contact = data.contact.id;
    data.mode = templateAutomationType;
    data.scheduledOn = moment(data.scheduledOn).format('YYYY-MM-DD');
    data.scheduledOn = new Date(data.scheduledOn + ' ' + data.dueTime).toISOString();
    if (data?.template && data?.template?.id) {
      data.template = data?.template?.id;
    } else {
      delete data.template;
    }
    try {
      setLoading(true);
      if (editTask && editTask?.id && editTask?.id !== '') {
        await props.putTask(editTask?.id, data);
      } else {
        data.sourceType = 'manual';
        data.status = 'pending';
        await props.postTask(data);
      }
      setLoading(false);
    } catch (error) {}
  };

  const customValues = useMemo(() => {
    const value = {};
    for (let i = 0; i < customFields.length; i++) {
      const field = customFields[i];
      value[field.fieldName] =
        editTask && editTask[field.fieldName] ? editTask[field.fieldName] : '';
    }
    return value;
  }, [customFields, editTask]);

  const linkedinTypes = [
    'linkedin',
    'linkedinConnection',
    'linkedinMessage',
    'linkedinMail',
    'linkedinViewProfile',
  ];

  return (
    <Form
      initialValues={{
        taskType: editTask?.taskType || '',
        subject: editTask?.id ? editTask?.subject || editUserTask?.subject : '',
        template: editTask?.id ? editUserTask : '',
        description: editTask?.description || '',
        priority: editTask?.priority || priorities?.length ? priorities[1].id : '',
        scheduledOn: editTask?.scheduledOn ? moment(editTask?.scheduledOn) : null,
        dueTime: editTask?.scheduledOn ? moment(editTask?.scheduledOn).format('HH:mm:ss') : '',
        assignedTo: user,
        contact: contact,
        ...customValues,
      }}
      validationSchema={Yup.object().shape({
        taskType: Yup.string().required('Please select task type!'),
        description: Yup.string().required('Please enter Message!'),
        scheduledOn: Yup.date('Please enter a valid date.').min(
          moment().subtract(1, 'days'),
          'Due date cannot be in past!',
        ),
        contact: Yup.object()
          .required('Please choose a Contact to continue.')
          .nullable(true)
          .test(
            'candidate-check',
            'Please choose a Contact to continue.',
            async (value, { createError }) => {
              if (value && value.id && value.id !== '') {
                return true;
              }
              return false;
            },
          ),
        assignedTo: Yup.object()
          .required('Please choose a assigned user to continue.')
          .nullable(true)
          .test(
            'assignedTo-check',
            'Please choose a assigned user to continue.',
            async (value, { createError }) => {
              if (value && value.id && value.id !== '') {
                return true;
              } else if (props.user?.id) {
                return true;
              }
              return false;
            },
          ),
        ...(customFields &&
          customFields.length &&
          Object.assign(
            {},
            ...customFields
              .filter((item) => item.fieldOptions?.required && item.fieldOptions?.required === true)
              .map((field) => ({
                [field.fieldName]: Yup.string().required('This field is required!'),
              })),
          )),
      })}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={false}
    >
      {({ values, ...formProps }) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formProps.submitForm();
              return false;
            }}
            noValidate
          >
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-start"
              height="100%"
            >
              <Stack width="100%" gap={'8px'}>
                <Form.Field.Select
                  options={taskTypes || []}
                  fullWidth
                  variant="outlined"
                  name="taskType"
                  label="Task type"
                  optLabel="name"
                  optValue="id"
                  showNone={false}
                  onChange={() => {
                    formProps.setFieldValue('template', {});
                  }}
                />
                {tenant?.featureAccess?.linkedinAutomation &&
                  values.taskType.includes('linkedin') && (
                    <>
                      <SequenceFormTabBar
                        templateAutomationType={templateAutomationType}
                        setTemplateAutomationType={setTemplateAutomationType}
                      />
                      <Spacer y={0.5} />
                    </>
                  )}
                {['selectEmailTemplate', 'email'].includes(values.taskType) && (
                  <Form.Field.AutoComplete
                    multiple={false}
                    fullWidth
                    options={[]}
                    variant="outlined"
                    name="template"
                    label="Choose Template"
                    InputLabelProps={{ shrink: !!values?.template?.id }}
                    optLabel="name"
                    optValue="id"
                    remoteMethod={(val) => {
                      return fetchTemplatesLookup(
                        val,
                        ['selectEmailTemplate', 'email'].includes(values.taskType)
                          ? 'email'
                          : 'linkedin,linkedinMessage,linkedinViewProfile,linkedinMail,linkedinConnection',
                      );
                    }}
                    onChange={(val) => {
                      formProps.setFieldValue('subject', val.subject);
                      formProps.setFieldValue('description', val.content);
                    }}
                  />
                )}
                {(['selectEmailTemplate', 'email'].includes(values.taskType) ||
                  values.taskType === 'linkedinMail') && (
                  <>
                    <Form.Field.Input
                      name="subject"
                      label="Subject"
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{ shrink: !!(editTask?.id || values?.subject?.id) }}
                      showPicker={true}
                      options={replacementVariables}
                      optValue="value"
                      optLabel="text"
                      emojiPicker={true}
                    />
                  </>
                )}

                <Form.Field.TextEditor
                  key={[...linkedinTypes, 'sms'].includes(values.taskType) ? 'lEditor' : 'eEditor'}
                  fullWidth
                  multiline
                  rows={2}
                  variant="outlined"
                  name="description"
                  pasteAsText={[...linkedinTypes, 'sms'].includes(values.taskType)}
                  label={
                    [...linkedinTypes, 'sms'].includes(values.taskType) &&
                    values.taskType !== 'linkedinViewProfile'
                      ? 'Message'
                      : 'Task notes'
                  }
                  placeHolder={
                    [...linkedinTypes, 'sms'].includes(values.taskType) &&
                    values.taskType !== 'linkedinViewProfile'
                      ? 'Enter the message you’d like to send.This can be amended before sending.'
                      : 'Enter information to help you complete the task...'
                  }
                  showFontStyle={
                    ![...linkedinTypes, 'general', 'call', 'sms'].includes(values.taskType)
                  }
                  showFontEditor={
                    ![...linkedinTypes, 'general', 'call', 'sms'].includes(values.taskType)
                  }
                  showAlignProps={
                    ![...linkedinTypes, 'general', 'call', 'sms'].includes(values.taskType)
                  }
                  showListProps={
                    ![...linkedinTypes, 'general', 'call', 'sms'].includes(values.taskType)
                  }
                  showAttachments={
                    ![...linkedinTypes, 'general', 'call', 'sms'].includes(values.taskType)
                  }
                  showPlaceholder={!['general', 'linkedinViewProfile'].includes(values.taskType)}
                />

                <Form.Field.AutoComplete
                  options={contact ? [contact] : []}
                  multiple={false}
                  fullWidth
                  variant="outlined"
                  showAvatar={false}
                  remoteMethod={(val) => {
                    return searchCandidates(val, 'contact');
                  }}
                  name="contact"
                  label="Contact"
                  optLabel="name"
                  optValue="id"
                  disabled={editTask?.id || false}
                />
                <Form.Field.AutoComplete
                  multiple={false}
                  fullWidth
                  showAvatar={false}
                  InputLabelProps={{ shrink: !!values?.assignedTo?.name }}
                  options={[user]}
                  variant="outlined"
                  remoteMethod={(val) => {
                    return searchCandidates(val, 'assignedTo');
                  }}
                  name="assignedTo"
                  label="Assigned to"
                  optLabel="name"
                  optValue="id"
                  allowAdd={true}
                />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Form.Field.Datepicker
                      fullWidth
                      variant="inline"
                      name="scheduledOn"
                      label="Due date"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Form.Field.Timepicker
                      fullWidth
                      variant="outlined"
                      name="dueTime"
                      label="Due Time"
                    />
                  </Grid>
                </Grid>
                {customFields.length > 0 ? (
                  <>
                    <Spacer x={3} y={3} />
                    <TitleBar title="Custom Fields" tooltip="Custom Fields" />
                    <Spacer x={1} y={1} />
                    <Grid container spacing={2}>
                      {customFields.map((field, index) => {
                        if (
                          field?.fieldOptions &&
                          field?.fieldOptions?.showOnForm &&
                          field?.fieldOptions?.showOnForm === true
                        ) {
                          return (
                            <Grid key={`sequence-custom-field-${index}`} item xs={6}>
                              <Form.Field.CustomField attribute={field} name={field.fieldName} />
                            </Grid>
                          );
                        }
                        return null;
                      })}
                    </Grid>
                  </>
                ) : null}
              </Stack>

              <Box display="flex" mt={2} justifyContent="flex-end" width="100%">
                <Button
                  variant="outlined"
                  color="secondary"
                  disabled={loading}
                  onClick={() => {
                    toggleAddTaskForm();
                  }}
                >
                  Cancel
                </Button>
                <Spacer basis={2} />
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        );
      }}
    </Form>
  );
}

const mapStateToProps = (state) => ({
  taskOptions: state.tasks.taskOptions,
  user: state.auth.user,
  editTask: state.app.editTask,
  taskPriorityList: state.app.globals.taskPriorityList,
  taskTypeList: state.app.globals.taskTypeList,
  taskFields: getTaskFields(state),
});

const mapDispatchToProps = (dispatch) => ({
  toggleAddTaskForm: () => dispatch(toggleAddTaskForm()),
  postTask: (task) => {
    return new Promise((resolve, reject) => {
      dispatch(postTask(task, resolve, reject));
    });
  },
  putTask: (id, task) => {
    return new Promise((resolve, reject) => {
      dispatch(putTask(id, task, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskForm);
