import React from 'react';
import * as Yup from 'yup';
import { Typography, InputAdornment, Stack, useTheme } from '@mui/material';
import styled from 'styled-components';
import { green } from '@mui/material/colors';
import { Form, Button, Spacer } from 'src/components/shared';

import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const lowerCaseRegex = new RegExp('^(?=.*[a-z])');
const upperCaseRegex = new RegExp('(?=.*[A-Z])'); // Matches at least one uppercase letter
const numberRegex = new RegExp('^(?=.*[0-9])');
// const specialCharRegex = new RegExp('^(?=.*[!@#$%^&*])');
const specialCharRegex = new RegExp(/[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/);
const eightChatRegex = new RegExp('^(?=.{8,})');

import images from '../../../../config/images';

const PasswordLintAlert = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;
`;

const PasswordLint = ({ isValid, title }) => {
  console.log('===isValid, title', isValid, title);
  const theme = useTheme();
  return (
    <PasswordLintAlert>
      {isValid ? (
        <CheckCircleIcon
          style={{
            color: green[800],
          }}
        />
      ) : (
        <ErrorOutlineIcon
          sx={(theme) => ({
            color: theme.palette.background.lightGray,
          })}
        />
      )}
      <Spacer x={1} y={1} />
      <Typography color={isValid ? green[800] : theme.palette.background.lightGray}>
        {title}
      </Typography>
    </PasswordLintAlert>
  );
};

const RegisterSetPasswordForm = ({ onSubmit, formData, loading }) => {
  return (
    <React.Fragment>
      <div style={{ maxWidth: 400, margin: '0 auto' }}>
        <Form
          validateOnChange={true}
          initialValues={{
            email: formData.email,
            password: '',
            confirmPassword: '',
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .required('Please enter your password')
              .min(8, 'Password must be at least 8 characters long')
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]).{8,}$/,
                'Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character',
              ),
            confirmPassword: Yup.string()
              .required('Please enter your confirm password')
              .oneOf([Yup.ref('password'), null], 'Password do not match'),
          })}
          onSubmit={async (values) => onSubmit(values)}
        >
          {(props) => {
            const isAllow =
              eightChatRegex.test(props.values.password) &&
              lowerCaseRegex.test(props.values.password) &&
              upperCaseRegex.test(props.values.password) &&
              numberRegex.test(props.values.password) &&
              specialCharRegex.test(props.values.password);
            return (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  props.submitForm();
                  return false;
                }}
                noValidate
              >
                <Form.Field.Input
                  fullWidth
                  page="auth"
                  errorWithIcon
                  variant="outlined"
                  name="email"
                  placeholder="Work Email"
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailOutlinedIcon sx={(theme) => ({ fill: theme.palette.text.grey6 })} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Form.Field.Input
                  fullWidth
                  page="auth"
                  errorWithIcon
                  variant="outlined"
                  type="password"
                  name="password"
                  placeholder="Set a password"
                  disabled={loading}
                  errorBorder
                  autoComplete="current-password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={images.icons.lockIcon} alt="info" />
                      </InputAdornment>
                    ),
                  }}
                />
                {eightChatRegex.test(props.values.password) &&
                lowerCaseRegex.test(props.values.password) &&
                upperCaseRegex.test(props.values.password) &&
                numberRegex.test(props.values.password) &&
                specialCharRegex.test(props.values.password) ? null : (
                  <div>
                    <PasswordLint
                      isValid={eightChatRegex.test(props.values.password)}
                      title="At least 8 characters long"
                    />
                    <PasswordLint
                      isValid={lowerCaseRegex.test(props.values.password)}
                      title="One lowercase character"
                    />
                    <PasswordLint
                      isValid={upperCaseRegex.test(props.values.password)}
                      title="One uppercase character"
                    />
                    <PasswordLint
                      isValid={numberRegex.test(props.values.password)}
                      title="One numeric character"
                    />
                    <PasswordLint
                      isValid={specialCharRegex.test(props.values.password)}
                      title="One special character"
                    />
                  </div>
                )}
                <Form.Field.Input
                  fullWidth
                  page="auth"
                  errorWithIcon
                  variant="outlined"
                  errorBorder
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm password"
                  disabled={loading}
                  autoComplete="current-password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={images.icons.lockIcon} alt="info" />
                      </InputAdornment>
                    ),
                  }}
                />
                <Stack mt={2} alignItems={'flex-end'} justifyContent={'end'}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{ width: 'fit-content' }}
                    loading={loading}
                    size="large"
                    errorBorder
                    disabled={!props?.isValid || loading || !props?.dirty || !isAllow}
                    blackButton
                    endIcon={<img src={images.icons.nextIcon} alt="info" />}
                  >
                    Next
                  </Button>
                </Stack>

                <Spacer x={2} y={2} />
              </form>
            );
          }}
        </Form>
      </div>
    </React.Fragment>
  );
};

export default RegisterSetPasswordForm;
