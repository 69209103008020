import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Grid, Box, Chip } from '@mui/material';
import { Button, Form, Spacer } from 'src/components/shared';
import Typography from '@mui/material/Typography';
import { fetchAdminDomainLookup, fetchAdminIndustryLookup } from '../../api/authApis';
import { isArray } from 'lodash';

function AISettings({ editUser, putUser }) {
  const [loading, setLoading] = useState(false);

  const {
    domain = '', // default value if domain is null or undefined
    industryExperience = '', // default value if industryExperience is null or undefined
  } = editUser?.roleplaySettings ?? {};

  const handleSubmit = async (values, form) => {
    try {
      setLoading(true);
      const data = { roleplaySettings: { ...values } };
      if (editUser && editUser?.id) {
        const updatedRow = { id: editUser?.id, data };
        await putUser(updatedRow);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Form
      initialValues={{
        domain: (isArray(domain) ? domain[0] : domain) ?? {},
        industryExperience:
          (isArray(industryExperience) ? industryExperience[0] : industryExperience) ?? {},
      }}
      validationSchema={Yup.object().shape({
        domain: Yup.object().test('domain', 'Please choose a domain.', function (item) {
          return !!item?.value;
        }),
        industryExperience: Yup.object().test(
          'industryExperience',
          'Please choose a industry.',
          function (item) {
            return !!item?.value;
          },
        ),
      })}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={false}
    >
      {(props) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            style={{ height: '100%' }}
            noValidate
          >
            <Box display="flex" flexDirection="column" height="100%">
              <Box>
                <Typography variant="h4" gutterBottom>
                  AI Learning Settings
                </Typography>
                <Spacer x={1} y={1} />
                <Typography variant="body2" color="textSecondary">
                  Choose your domain and industry specialism to ensure every roleplay is
                  personalised to your exact needs.
                </Typography>
              </Box>
              <Spacer x={2} y={2} />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Form.Field.AutoComplete
                    name="domain"
                    options={[]}
                    fullWidth={true}
                    variant="outlined"
                    remoteMethod={(val) => {
                      return fetchAdminDomainLookup(val);
                    }}
                    optLabel="label"
                    optValue="value"
                    label="Domain"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Form.Field.AutoComplete
                    name="industryExperience"
                    options={[]}
                    fullWidth={true}
                    variant="outlined"
                    label="Industry expertise"
                    remoteMethod={(val) => {
                      return fetchAdminIndustryLookup(val);
                    }}
                    optLabel="label"
                    optValue="value"
                  />
                </Grid>
              </Grid>

              <Box mt={2} display="flex">
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        );
      }}
    </Form>
  );
}

export default AISettings;
