import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';

import RegistrationForm from '../../components/Register/RegistrationForm';
import PersonalDetailsForm from '../../components/Register/PersonalDetailsForm';
import RegisterSetPasswordForm from './RegisterFlowSetPassoword';
import { signUpJourneyGetData } from '../../api/authApis';

const utmKeys = ['utm_code', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_journey'];

export default function RegisterView(props) {
  const location = useLocation();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = React.useState(false);

  const [formData, setFormData] = useState({
    step: 1,
    email: '',
    termsAccepted: false,
    password: '',
    firstName: '',
    lastName: '',
    companyName: '',
    isVerified: false,
  });
  const params = new URLSearchParams(location?.search);
  const email = params.get('email');

  const utmParams = utmKeys.reduce(
    (acc, key) => {
      const urlKey = key === 'utm_code' ? 'ut_code' : key;
      const valueFromUrl = params.get(urlKey) || params.get(key) || '';
      const valueFromForm = formData.utmParams?.[key] || '';
      acc[key] = valueFromUrl || valueFromForm;
      return acc;
    },
    { ...formData.utmParams },
  );

  useEffect(() => {
    if (email) {
      getSSOSignUpUserData(email);
    }
  }, []);

  const getSSOSignUpUserData = async () => {
    setLoading(true);
    await signUpJourneyGetData(email)
      .then((res) => {
        if (res?.length > 0) {
          setStep(3);
          setFormData({ ...res[0], step: 3 });
        } else {
          setStep(1);
          props?.history?.push(`/auth/signup`);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const payload = {
        ...formData,
        ...data,
        step: formData.step,
        utmParams,
      };
      setFormData(payload);
      const res = await props.register(payload);
      setLoading(false);
      if (res) {
        if (step === 3) {
          props?.history?.push(`/auth/verification?email=${res?.email}&id=${res?.id}`);
        }
        if (res?.journeyStepsCompleted > 0) {
          if (res?.journeyStepsCompleted === 3) {
            props?.history?.push(`/auth/verification?email=${res?.email}&id=${res?.id}`);
          } else {
            setStep(res?.journeyStepsCompleted + 1);
            setFormData({ ...res, step: res?.journeyStepsCompleted + 1 });
          }
        } else {
          setStep((prevState) => prevState + 1);
          setFormData({ ...res, step: formData.step + 1 });
        }
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const renderComponent = () => {
    switch (step) {
      case 1:
        return (
          <RegistrationForm
            onSubmit={onSubmit}
            loading={loading}
            history={props?.history}
            registerReVerification={props?.registerReVerification}
          />
        );
      case 2:
        return (
          <RegisterSetPasswordForm onSubmit={onSubmit} formData={formData} loading={loading} />
        );
      case 3:
        return <PersonalDetailsForm onSubmit={onSubmit} formData={formData} loading={loading} />;
      default:
        return null;
    }
  };

  const renderTitle = () => {
    switch (step) {
      case 1:
        return 'Sign up for your free trial';
      case 2:
        return 'Set your password';
      case 3:
        return 'About you';
      default:
        return 'Personal Details';
    }
  };

  return (
    <React.Fragment>
      <Typography variant="h1" align="center">
        {renderTitle()}
      </Typography>
      <div style={{ height: 20 }} />
      <div style={{ maxWidth: 400, margin: '0 auto' }}>{renderComponent()}</div>
    </React.Fragment>
  );
}
